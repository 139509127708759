import IconButton from '@/components/common/IconButton';
import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import {
  faFileExport,
  faRefresh,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from '@/components/common/DatePicker';
import { DateTime } from 'luxon';

const SupplyHoursHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onStateChange,
}) => {
  const userConfig = useSelector(selectCurrentUserConfig);
  const [startDate, setStartDate] = useState(new Date());

  const handleDateChange = (date: Date) => {
    onStateChange?.((old: any) => {
      return {
        ...old,
        date: DateTime.fromJSDate(date).toFormat('yyyy-MM-dd'),
      };
    });
    setStartDate(date);
  };

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Supply hours</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Rechercher"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
        <Col xs="auto">
          <DatePicker
            value={startDate.toString()}
            onChange={handleDateChange}
          />
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <div id="orders-actions">
          {userConfig?.isAdminOrSuperAdmin && (
            <>
              <IconButton
                variant="nolina-default"
                size="sm"
                icon={faRefresh}
                transform="shrink-3"
                style={{ marginLeft: '10px' }}
                onClick={() => {}}
              >
                <span className="d-none d-sm-inline-block ms-1">Refresh</span>
              </IconButton>
              <a
                href={`${
                  process.env.REACT_APP_API_URI
                }/export/supplyhours/${DateTime.fromJSDate(startDate).toFormat(
                  'yyyy-MM-dd'
                )}`}
                rel="noreferrer"
              >
                <IconButton
                  variant="nolina-default"
                  size="sm"
                  icon={faFileExport}
                  transform="shrink-3"
                  style={{ marginLeft: '10px' }}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </a>
            </>
          )}
        </div>{' '}
      </div>
    </div>
  );
};

export default SupplyHoursHeader;
