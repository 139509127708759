import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useCallback, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import Loader from '@/components/common/Loader';
import { SelectPaginateAsync } from '@/components/common/Select';
import CardHeader from '@/components/card-headers/CardHeader';
import {
  LeafletMapDrawerShape,
  UpdateActivityAreaPickCardForm,
} from '@/interfaces/ActivityAreaPick';
import useLoadOptions from '@/hooks/useLoadOptions';
import { getTitleFieldCoordinatesPosition } from '@/helpers/ActivityAreaPickHelper';

import {
  useGetActivityAreaQuery,
  useStoreActivityAreaMutation,
  useUpdateActivityAreaMutation,
} from '@/services/activityArea';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';
import InputColor from '@/components/common/InputColor';
import LeafletMap from '@/components/common/map/LeafletMap/LeafletMap';
import { LeafletMapDrawerParams } from '@/components/common/map/LeafletMap/LeafletMapDrawerOverlay';
import LeafletMapDrawer from '@/components/common/map/LeafletMap/LeafletMapDrawer';
import { useRefWithCallback } from '@/helpers/utils';

interface UpdateActivityAreaPickCardProps {
  id: string;
}

const defaultValues: UpdateActivityAreaPickCardForm = {
  name: '',
  color: '#77aaf1',
  coordinates: {
    northEast: { lat: 0, lng: 0 },
    northWest: { lat: 0, lng: 0 },
    southWest: { lat: 0, lng: 0 },
    southEast: { lat: 0, lng: 0 },
  },
} as UpdateActivityAreaPickCardForm;

const UpdateActivityAreaPickCard: FC<UpdateActivityAreaPickCardProps> = ({
  id,
}) => {
  const { data, isLoading } = useGetActivityAreaQuery(id, {
    refetchOnFocus: true,
  });

  const [updateActivityArea, { isLoading: storeActivityAreaLoading }] =
    useUpdateActivityAreaMutation();
  const navigation = useNavigate();
  const {
    control,
    reset,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateActivityAreaPickCardForm>({
    defaultValues,
  });
  const [toogle, refCallback, LeafletMapDrawerRef] =
    useRefWithCallback<LeafletMapDrawerParams>();

  const { loadOffersOptions } = useLoadOptions();

  const onSubmit: SubmitHandler<UpdateActivityAreaPickCardForm> = ({
    ...newData
  }) => {
    if (!data) return;
    const result =
      getDifferenceBetweenTwoObjects<UpdateActivityAreaPickCardForm>(
        data,
        newData
      );
    console.log(result.coordinates);

    toast.promise(
      updateActivityArea([
        id,
        {
          ...result,
          coordinateNorthEast: result.coordinates
            ? result.coordinates.northEast
            : (null as never),
          coordinateSouthWest: result.coordinates
            ? result.coordinates.southWest
            : (null as never),
          availableOffers: data.offers?.map(offer => offer.id) ?? [],
        },
      ]).unwrap(),
      {
        pending: `Mise à jour de l'activity area...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`L'activity area à bien été modifiée 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<UpdateActivityAreaPickCardForm> = error =>
    console.log(error);

  // useEffect to watch for valid coordinates change
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('coordinates') && type === 'change') {
        const coordinates = value.coordinates as LeafletMapDrawerShape;
        const updatedCoordinates = { ...coordinates };

        // Check if a specific coordinate is being updated
        if (name === 'coordinates.northEast.lat') {
          const northEast = coordinates.northEast;
          if (northEast) {
            updatedCoordinates['northEast'].lat = northEast.lat;
            updatedCoordinates['northWest'].lat = northEast.lat; // Match latitude of northEast
            updatedCoordinates['southEast'].lat = northEast.lat; // Match latitude of northEast
          }
        } else if (name === 'coordinates.northEast.lng') {
          const northEast = coordinates.northEast;
          if (northEast) {
            updatedCoordinates['northEast'].lng = northEast.lng;
            updatedCoordinates['southEast'].lng = northEast.lng; // Match longitude of northEast
          }
        } else if (name === 'coordinates.southWest.lat') {
          const southWest = coordinates.southWest;
          if (southWest) {
            updatedCoordinates['southWest'].lat = southWest.lat;
            updatedCoordinates['northWest'].lat = southWest.lat; // Match latitude of southWest
            updatedCoordinates['southEast'].lat = southWest.lat; // Match latitude of southWest
          }
        } else if (name === 'coordinates.southWest.lng') {
          const southWest = coordinates.southWest;
          if (southWest) {
            updatedCoordinates['southWest'].lng = southWest.lng;
            updatedCoordinates['northWest'].lng = southWest.lng; // Match longitude of southWest
            updatedCoordinates['southEast'].lng = southWest.lng; // Match longitude of southWest
          }
        } else if (name === 'coordinates.northWest.lat') {
          const northWest = coordinates.northWest;
          if (northWest) {
            updatedCoordinates['northWest'].lat = northWest.lat;
            updatedCoordinates['southWest'].lat = northWest.lat; // Match latitude of northWest
            updatedCoordinates['northEast'].lat = northWest.lat; // Match latitude of northWest
          }
        } else if (name === 'coordinates.northWest.lng') {
          const northWest = coordinates.northWest;
          if (northWest) {
            updatedCoordinates['northWest'].lng = northWest.lng;
            updatedCoordinates['southWest'].lng = northWest.lng; // Match longitude of northWest
            updatedCoordinates['southEast'].lng = northWest.lng; // Match longitude of northWest
          }
        } else if (name === 'coordinates.southEast.lat') {
          const southEast = coordinates.southEast;
          if (southEast) {
            updatedCoordinates['southEast'].lat = southEast.lat;
            updatedCoordinates['northEast'].lat = southEast.lat; // Match latitude of southEast
            updatedCoordinates['southWest'].lat = southEast.lat; // Match latitude of southEast
          }
        } else if (name === 'coordinates.southEast.lng') {
          const southEast = coordinates.southEast;
          if (southEast) {
            updatedCoordinates['southEast'].lng = southEast.lng;
            updatedCoordinates['northEast'].lng = southEast.lng; // Match longitude of southEast
            updatedCoordinates['southWest'].lng = southEast.lng; // Match longitude of southEast
          }
        }

        // Check if coordinates are valid and update the form state
        const isValid = Object.values(updatedCoordinates).every(
          ({ lat, lng }) => lat !== 0 && lng !== 0
        );

        console.log({ isValid });

        if (isValid) {
          setValue('coordinates', updatedCoordinates);
          LeafletMapDrawerRef.current?.setShape(
            updatedCoordinates as LeafletMapDrawerShape
          );
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        coordinates: { ...data.coordinates },
        availableOffers: data.offers.map(offer => ({
          id: offer.id,
          name: offer.name,
        })),
      });
    }
  }, [data, isLoading, reset]);

  useEffect(() => {
    if (data) {
      if (LeafletMapDrawerRef.current) {
        LeafletMapDrawerRef.current.setShape(data.coordinates);
      }
    }
  }, [toogle, data]);

  if (isLoading) return <Loader />;

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Create new activity area" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Nom du lieu <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Nom du lieu"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Radius <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Radius"
                  type="number"
                  step="any"
                  {...register('radius', {
                    required: 'Ce champ est requis.',
                    valueAsNumber: true,
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.radius}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.radius?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-3">
            <Row lg={4}>
              <Col lg={6}>
                <Row
                  style={{
                    gap: '10px',
                  }}
                >
                  {(
                    Object.keys(defaultValues.coordinates!) as Array<
                      keyof LeafletMapDrawerShape
                    >
                  )?.map((coor, i) => (
                    <Col key={i}>
                      <Form.Group className="mb-3">
                        <Form.Label className="p-0">
                          Coordinate ({getTitleFieldCoordinatesPosition(i)}){' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Row>
                          <Form.Text className="p-0">
                            Longitude <span className="text-danger">*</span> :
                          </Form.Text>
                          <Form.Control
                            step="any"
                            placeholder="ex : 123.34"
                            type="number"
                            {...register(`coordinates.${coor}.lng`, {
                              required: 'Ce champ est requis.',
                              valueAsNumber: true,
                              validate: value => {
                                return value !== 0;
                              },
                              minLength: {
                                value: 0,
                                message:
                                  'Ce champ doit contenir au moins 2 caractères.',
                              },
                            })}
                            isInvalid={!!errors.coordinates}
                          />

                          <Form.Text className="p-0">
                            Latitude <span className="text-danger">*</span> :
                          </Form.Text>
                          <Form.Control
                            step="any"
                            placeholder="ex : 133.34"
                            type="number"
                            {...register(`coordinates.${coor}.lat`, {
                              valueAsNumber: true,
                              validate: value => {
                                return value !== 0;
                              },
                              minLength: {
                                value: 0,
                                message:
                                  'Ce champ doit contenir au moins 2 caractères.',
                              },
                            })}
                            isInvalid={!!errors.coordinates}
                          />
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          {errors.coordinates?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Color <span className="text-danger">*</span> :
                      </Form.Label>
                      <Controller
                        control={control}
                        name="color"
                        defaultValue="#f2f2f2"
                        rules={{
                          minLength: {
                            value: 2,
                            message:
                              'Ce champ doit contenir au moins 2 caractères.',
                          },
                          required: 'Ce champ est requis.',
                        }}
                        render={({ field }) => <InputColor {...field} />}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.color?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col lg={6}>
                <LeafletMapDrawer
                  ref={refCallback}
                  onShape={shape => {
                    setValue('coordinates', shape, { shouldValidate: true });
                  }}
                />
              </Col>
            </Row>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  MaxETA <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  step="any"
                  placeholder="ex : MaxETA"
                  type="number"
                  {...register('maxETA', {
                    required: 'Ce champ est requis.',
                    valueAsNumber: true,
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.maxETA}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxETA?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Available Offers <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="availableOffers"
                  rules={{
                    required: 'Ce champ est requis.',
                  }}
                  render={({ field }) => (
                    <SelectPaginateAsync
                      aria-invalid={!!errors.availableOffers}
                      required
                      loadOptions={loadOffersOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                      isMulti
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.availableOffers?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={storeActivityAreaLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateActivityAreaPickCard;
