import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import Company from '@/interfaces/Company';
import { UUID_REGEX } from '@/constants/regexs';
import CompanyDetailsCard from '@/components/cards/companies/CompanyDetailsCard';
import CompanyHeader from '@/components/card-headers/companies/CompanyHeader';
import StatisticsCard from '@/components/common/StatisticsCard';
import CompanyDriversTable from '@/components/cards/companies/CompanyDriversTable';
import { WeeklyReportTable } from '@/components/cards/companies/WeeklyReportTable';

const CompanyView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Company>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {company && <CompanyHeader id={id} />}
      <>
        <Row className="g-3 mb-3">
          <Col xxl={12}>
            <CompanyDetailsCard id={id} card callBack={setCompany} />
          </Col>
        </Row>

        <Row className="flex-between-center">
          <Col md={4}>
            <StatisticsCard
              title="Number of Drivers"
              value={company?.driverCount ?? 0}
              badgeBg="info"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xxl={12}>
            <CompanyDriversTable companyId={id} />
          </Col>
          <Col xxl={12} className="mt-4">
            <WeeklyReportTable companyId={id} />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default CompanyView;
