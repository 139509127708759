import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import { Button, Card, CardHeader, Col, Row } from 'react-bootstrap';
import IconButton from '@/components/common/IconButton';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { IMessageFormSend } from '@/interfaces/IMessage';
import {
  useGetMessageByIdQuery,
  useUpdateWaMessagesMutation,
} from '@/services/waMessages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const WaMessagesEditView: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGetMessageByIdQuery(id!);

  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMessageFormSend>();

  const [editWaMessagesAsync] = useUpdateWaMessagesMutation();

  const onSubmit: SubmitHandler<IMessageFormSend> = data => {
    toast.promise(
      editWaMessagesAsync([
        id!,
        { ...data, type: 'driver_cancelled' },
      ]).unwrap(),
      {
        pending: `Modification du message en cours...`,
        success: {
          render() {
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le message a bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IMessageFormSend>> = error =>
    console.log(error);

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={() => navigation(-1)}
            variant="nolina-default"
            size="sm"
            icon={faArrowLeft}
          />
        </Card.Header>
      </Card>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Card>
            <CardHeader title="Modification du message" className="m-0" />
            <Card.Body className="bg-light">
              <Row className="gx-3">
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Title <span className="text-danger">*</span> :
                    </Form.Label>
                    <Form.Control
                      placeholder="Driver Cancelled"
                      type="text"
                      defaultValue={data?.title}
                      {...register('title', {
                        required: 'Ce champ est requis.',
                        minLength: {
                          value: 1,
                          message:
                            'Ce champ doit contenir au moins 1 caractères.',
                        },
                        maxLength: {
                          value: 255,
                          message:
                            'Ce champ doit contenir au maximum 255 caractères.',
                        },
                      })}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg="12" className="mt-2">
                  <Form.Label>Messages</Form.Label>
                  <Form.Control
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    as="textarea"
                    type="text"
                    defaultValue={data?.messages}
                    {...register('messages', {
                      minLength: {
                        value: 3,
                        message:
                          'Ce champ doit contenir au moins 3 caractères.',
                      },
                      maxLength: {
                        value: 2000,
                        message:
                          'Ce champ doit contenir au plus 255 caractères.',
                      },
                    })}
                    style={{ minHeight: 100 }}
                  />
                </Col>
              </Row>
              <Button
                type="submit"
                variant="success"
                disabled={isLoading}
                className="mt-3"
              >
                Confirmer
              </Button>
            </Card.Body>
          </Card>
        </Form>
      </Card>
    </div>
  );
};

export default WaMessagesEditView;
