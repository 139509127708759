import IDriverBlocked from '../DriverBlocked';
import DriverRequest from '../DriverRequest';
import IDriverService from '../DriverService';
import DriverVehicle from '../DriverVehicle';
import IUser from '../User';
import Company from '../Company';
import { ISelectOption } from '../Select';
import {
  getReelNameFromDriverExperienceYears,
  getReelNameFromDriverPrimaryShift,
  getReelNameFromWeeklyDrivingHours,
} from '@/helpers/DriverHelpers';
import IFile from '../IFile';
import { IDrivingPreferences } from '../DrivingPreferences';

const DRIVER_EXPERIENCE_YEARS_TYPES = [
  'LessThanOneYear',
  'OneToTwoYears',
  'ThreeToFiveYears',
  'SixToTenYears',
  'MoreThanTenYears',
] as const;

const WEEKLY_DRIVING_HOURS_TYPES = [
  'LessThan10Hours',
  '10To20Hours',
  '21To30Hours',
  '31To40Hours',
  'MoreThan40Hours',
] as const;

const DRIVER_PRIMARY_SHIFT_TYPES = ['day', 'night', 'both'] as const;

export const DRIVER_EXPERIENCE_YEARS_TYPES_SELECT: ISelectOption<DriverExperienceYears>[] =
  DRIVER_EXPERIENCE_YEARS_TYPES.map(v => ({
    label: getReelNameFromDriverExperienceYears(v),
    value: v,
  }));

export const WEEKLY_DRIVING_HOURS_TYPES_SELECT: ISelectOption<WeeklyDrivingHours>[] =
  WEEKLY_DRIVING_HOURS_TYPES.map(v => ({
    label: getReelNameFromWeeklyDrivingHours(v),
    value: v,
  }));

export const DRIVER_PRIMARY_SHIFT_TYPES_SELECT: ISelectOption<DriverPrimaryShift>[] =
  DRIVER_PRIMARY_SHIFT_TYPES.map(v => ({
    label: getReelNameFromDriverPrimaryShift(v),
    value: v,
  }));

export type DriverExperienceYears =
  (typeof DRIVER_EXPERIENCE_YEARS_TYPES)[number];

export type WeeklyDrivingHours = (typeof WEEKLY_DRIVING_HOURS_TYPES)[number];

export type DriverPrimaryShift = (typeof DRIVER_PRIMARY_SHIFT_TYPES)[number];

export type DriverNavigationApp = 'waze' | 'google' | 'apple';

export type DriverTier = 1 | 2 | 3;

interface Driver {
  acceptedRidesCount: number;
  averageRating: number | null;
  acceptedRate: number | null;
  services: IDriverService[];
  company: Company | null;
  defaultVehicle: DriverVehicle | null;
  licensePlate: string;
  lastFullLocation: string | null;
  id: string;
  isActive: boolean;
  isArchived: boolean;
  userId: string;
  user: IUser;
  blockedProfile: IDriverBlocked | null;
  lastCompletedRideRequest: DriverRequest | null;
  biography: string | null;
  latitude: number | null;
  longitude: number | null;
  preferences: IDrivingPreferences[] | null;
  primaryShift: DriverPrimaryShift;
  driverExperienceYears: DriverExperienceYears;
  weeklyDrivingHours: WeeklyDrivingHours;
  preferredNavigationApp: DriverNavigationApp;
  lastLocationAt: string | null;
  locationSource: string;
  locationGranted: boolean;
  points: number;
  completedRidesCount: number;
  referral: IUser | null;
  referralCode: string;
  tier: DriverTier;
  referralsCount: number | null;
  cancelledRate: number | null;
  totalEarned: number | null;
  createdAt: string;
  updatedAt: string;
  isCompanyRattachement: boolean;
  referredUsersCount: number;
  commissionRate: number;
  appVersion: string;
  appOS: string;
  lastCallDate: string | null;
}

export interface CompanyDrivers {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar: IFile;
  completedRides: string;
  lastKnownLocation?: string;
  services: IDriverService[];
  latitude: number | null;
  longitude: number | null; 
  defaultVehicle: DriverVehicle | null;
}

export interface IDriverForm
  extends Pick<Driver, 'referral' | 'userId' | 'company'> {
  driverExperienceYears: ISelectOption<DriverExperienceYears>;
  weeklyDrivingHours: ISelectOption<WeeklyDrivingHours>;
  primaryShift: ISelectOption<DriverPrimaryShift>;
  referralCode: string;
  commissionRate?: ISelectOption<number>;
  isCompanyRattachement?: boolean;

}

export interface IDriverFormCSV
  extends Pick<Driver, 'referral' | 'company' | 'userId'> {
  driverExperienceYears: ISelectOption<DriverExperienceYears>;
  weeklyDrivingHours: ISelectOption<WeeklyDrivingHours>;
  primaryShift: ISelectOption<DriverPrimaryShift>;
}

export interface IReferralDriverForm {
  amountPerRide: number;
  userNbOfRidesCompleted: number;
  driverNbOfRidesCompleted: number;
}

export interface IDriverFormUpdate extends IDriverForm {
  isCompanyRattachement?: boolean;
}

export interface IDriverFormSend
  extends Omit<
    IDriverForm,
    'referral' | 'company' | 'primaryShift' | 'driverExperienceYears' | 'weeklyDrivingHours'
  > {
  driverExperienceYears: DriverExperienceYears;
  weeklyDrivingHours: WeeklyDrivingHours;
  primaryShift: DriverPrimaryShift;
  companyId?: string;
  referralCode: string;
  commissionRate?: ISelectOption<number> | undefined;
  isCompanyRattachement?: boolean;
}


export interface IDriverFormSendCSV
  extends Omit<
    IDriverFormCSV,
    | 'referral'
    | 'company'
    | 'driverExperienceYears'
    | 'weeklyDrivingHours'
    | 'primaryShift'
    | 'userId'
  > {
  driverExperienceYears: DriverExperienceYears;
  weeklyDrivingHours: WeeklyDrivingHours;
  primaryShift: DriverPrimaryShift;
  companyId?: string;
  userId?: string;
}

export interface IDriverFormSendCSV
  extends Omit<
    IDriverFormCSV,
    | 'referral'
    | 'company'
    | 'driverExperienceYears'
    | 'weeklyDrivingHours'
    | 'primaryShift'
    | 'userId'
  > {
  driverExperienceYears: DriverExperienceYears;
  weeklyDrivingHours: WeeklyDrivingHours;
  primaryShift: DriverPrimaryShift;
  companyId?: string;
  userId?: string;
  commissionRate?: number;
  referralCode: string;
}


export interface IDriverFormSendUpdate
  extends Omit<IDriverFormSend, 'userId'> {
  isCompanyRattachement?: boolean;
}

export const ComissionRateOptions: ISelectOption<number>[] = [
  {
    value: 10,
    label: '10%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 0,
    label: '0%',
  },
];

export default Driver;
