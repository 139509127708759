import api from '../api';
import { FeatureTriggerT } from '@/interfaces/FeatureTrigger';

export const featuresTriggerApi = api.injectEndpoints({
  endpoints: build => ({
    getAllFeatures: build.query<Array<FeatureTriggerT>, void>({
      query: () => '/featuresTrigger',
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.map(({ id }) => ({
              type: 'FeaturesTrigger' as const,
              id,
            })),
            { type: 'FeaturesTrigger', id: 'LIST' },
          ]
          : [],
    }),

    updateFeature: build.mutation<void, [boolean, string]>({
      query: ([body, id]) => ({
        url: `/featuresTrigger/${id}`,
        method: 'PUT',
        body: { value: body },
      }),
      invalidatesTags: [{ type: 'FeaturesTrigger', id: 'LIST' }],
    }),

  }),
  overrideExisting: true,
});

export const {
  useGetAllFeaturesQuery,
  useUpdateFeatureMutation,
} = featuresTriggerApi;
