import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import { SelectPaginateAsync } from '@/components/common/Select';
import CardHeader from '@/components/card-headers/CardHeader';
import { IArticleForm } from '@/interfaces/Article';
import {
  CreateActivityAreaPickCardForm,
  LeafletMapDrawerShape,
} from '@/interfaces/ActivityAreaPick';
import useLoadOptions from '@/hooks/useLoadOptions';
import { getTitleFieldCoordinatesPosition } from '@/helpers/ActivityAreaPickHelper';

import { useStoreActivityAreaMutation } from '@/services/activityArea';
import InputColor from '@/components/common/InputColor';
import LeafletMapDrawer from '@/components/common/map/LeafletMap/LeafletMapDrawer';
import { LeafletMapDrawerParams } from '@/components/common/map/LeafletMap/LeafletMapDrawerOverlay';

const defaultValues: CreateActivityAreaPickCardForm = {
  name: '',
  color: '#77aaf1',
  coordinates: {
    northWest: { lat: 0, lng: 0 },
    northEast: { lat: 0, lng: 0 },
    southWest: { lat: 0, lng: 0 },
    southEast: { lat: 0, lng: 0 },
  },
} as CreateActivityAreaPickCardForm;

const CreateActivityAreaPick: FC = () => {
  const [storeActivityArea, { isLoading: storeActivityAreaLoading }] =
    useStoreActivityAreaMutation();
  const navigation = useNavigate();
  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateActivityAreaPickCardForm>({
    defaultValues,
  });
  const LeafletMapDrawerRef = useRef<LeafletMapDrawerParams>(null);

  const { loadOffersOptions } = useLoadOptions();

  const onSubmit: SubmitHandler<CreateActivityAreaPickCardForm> = ({
    ...data
  }) => {
    toast.promise(
      storeActivityArea({
        ...data,
        coordinateNorthEast: data.coordinates.northEast,
        coordinateSouthWest: data.coordinates.southWest,
        availableOffers: data.availableOffers?.map(offer => offer.id) ?? [],
      }).unwrap(),
      {
        pending: `Création de l'activity area...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`L'activity area à bien été crée 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IArticleForm> = error => console.log(error);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('coordinates') && type === 'change') {
        const coordinates = value.coordinates as LeafletMapDrawerShape;
        const updatedCoordinates = { ...coordinates };

        // Handle changes for latitude and longitude, updating related points
        switch (name) {
          case 'coordinates.northEast.lat':
            updatedCoordinates.northWest.lat = coordinates.northEast.lat;
            break;

          case 'coordinates.northEast.lng':
            updatedCoordinates.southEast.lng = coordinates.northEast.lng;
            break;

          case 'coordinates.southWest.lat':
            updatedCoordinates.southEast.lat = coordinates.southWest.lat;
            break;

          case 'coordinates.southWest.lng':
            updatedCoordinates.northWest.lng = coordinates.southWest.lng;
            break;

          case 'coordinates.northWest.lat':
            updatedCoordinates.northEast.lat = coordinates.northWest.lat;
            break;

          case 'coordinates.northWest.lng':
            updatedCoordinates.southWest.lng = coordinates.northWest.lng;
            break;

          case 'coordinates.southEast.lat':
            updatedCoordinates.southWest.lat = coordinates.southEast.lat;
            break;

          case 'coordinates.southEast.lng':
            updatedCoordinates.northEast.lng = coordinates.southEast.lng;
            break;

          default:
            break;
        }

        // Validate if all coordinates are valid (non-zero values)
        const isValid = Object.values(updatedCoordinates).every(
          ({ lat, lng }) => lat !== 0 && lng !== 0
        );

        console.log({ isValid });

        if (isValid) {
          // Update form state and set shape on the map
          setValue('coordinates', updatedCoordinates);
          LeafletMapDrawerRef.current?.setShape(
            updatedCoordinates as LeafletMapDrawerShape
          );
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Create new activity area" className="m-0" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Nom du lieu <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Nom du lieu"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Radius <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Radius"
                  type="number"
                  step="any"
                  {...register('radius', {
                    required: 'Ce champ est requis.',
                    valueAsNumber: true,
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.radius}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.radius?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-3">
            <Row lg={4}>
              <Col lg={6}>
                <Row
                  style={{
                    gap: '10px',
                  }}
                >
                  {(
                    Object.keys(defaultValues.coordinates) as Array<
                      keyof typeof defaultValues.coordinates
                    >
                  )?.map((coor, i) => (
                    <Col key={i}>
                      <Form.Group className="mb-3">
                        <Form.Label className="p-0">
                          Coordinate ({getTitleFieldCoordinatesPosition(i)}){' '}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Row>
                          <Form.Text className="p-0">
                            Latitude <span className="text-danger">*</span> :
                          </Form.Text>
                          <Form.Control
                            step="any"
                            placeholder="ex : 133.34"
                            type="number"
                            {...register(`coordinates.${coor}.lat`, {
                              valueAsNumber: true,
                              validate: value => {
                                return value !== 0;
                              },
                              minLength: {
                                value: 0,
                                message:
                                  'Ce champ doit contenir au moins 2 caractères.',
                              },
                            })}
                            isInvalid={!!errors.coordinates}
                          />

                          <Form.Text className="p-0">
                            Longitude <span className="text-danger">*</span> :
                          </Form.Text>
                          <Form.Control
                            step="any"
                            placeholder="ex : 123.34"
                            type="number"
                            {...register(`coordinates.${coor}.lng`, {
                              required: 'Ce champ est requis.',
                              valueAsNumber: true,
                              validate: value => {
                                return value !== 0;
                              },
                              minLength: {
                                value: 0,
                                message:
                                  'Ce champ doit contenir au moins 2 caractères.',
                              },
                            })}
                            isInvalid={!!errors.coordinates}
                          />
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          {errors.coordinates?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Color <span className="text-danger">*</span> :
                      </Form.Label>
                      <Controller
                        control={control}
                        name="color"
                        defaultValue="#f2f2f2"
                        rules={{
                          minLength: {
                            value: 2,
                            message:
                              'Ce champ doit contenir au moins 2 caractères.',
                          },
                          required: 'Ce champ est requis.',
                        }}
                        render={({ field }) => <InputColor {...field} />}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.maxETA?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col lg={6}>
                <LeafletMapDrawer
                  ref={LeafletMapDrawerRef}
                  color={watch('color')}
                  onShape={shape => {
                    console.log({ shape });

                    setValue('coordinates', shape, { shouldValidate: true });
                  }}
                />
              </Col>
            </Row>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  MaxETA <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  step="any"
                  placeholder="ex : MaxETA"
                  type="number"
                  {...register('maxETA', {
                    required: 'Ce champ est requis.',
                    valueAsNumber: true,
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.maxETA}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxETA?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Available Offers <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="availableOffers"
                  rules={{
                    required: 'Ce champ est requis.',
                  }}
                  render={({ field }) => (
                    <SelectPaginateAsync
                      aria-invalid={!!errors.availableOffers}
                      required
                      loadOptions={loadOffersOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                      isMulti
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.availableOffers?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={storeActivityAreaLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default CreateActivityAreaPick;
