import { IFilters, IPaginateResponse } from "@/interfaces/Fetch";
import { createFormDataRequest } from "../helpers/utils";
import api from "./api";
import { IWeeklyChallenge, IWeeklyChallengeForm, IWeeklyChallengeFormCreateSend } from "@/interfaces/IWeeklyChallenges";

export const weeklyChallenges = api.injectEndpoints({
  endpoints: (build) => ({
    getWeeklyChallenges: build.query<IPaginateResponse<IWeeklyChallenge>, IFilters>({
      query: (data) => {
        const params = createFormDataRequest(data);
        return {
          url: "/weeklyChallenges",
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ id }) => ({
              type: "WeeklyChallenges" as const,
              id,
            })),
            { type: "WeeklyChallenges", id: "LIST", ...filters },
          ]
          : [],
    }),
    getWeeklyChallenge: build.query<IWeeklyChallenge, string>({
      query: id => `/weeklyChallenges/${id}`,
      providesTags: (_result, _error, id) => [{ type: 'WeeklyChallenges', id }],
    }),
    createWeeklyChallenge: build.mutation<IWeeklyChallenge, IWeeklyChallengeFormCreateSend>({
      query: weeklyChallenges => {
        return {
          url: '/weeklyChallenges',
          method: 'POST',
          body: weeklyChallenges,
        }
      },
      invalidatesTags: (result, _err, _body) => [
        { type: 'WeeklyChallenges', id: result?.id },
      ],
    }),
    destroyWeeklyChallenge: build.mutation<IWeeklyChallenge, string>({
      query: id => ({
        url: `/weeklyChallenges/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _err, body) => [
        { type: 'WeeklyChallenges', id: body },
      ],
    }),
    updateWeeklyChallenge: build.mutation<IWeeklyChallenge, [string, Partial<IWeeklyChallengeForm>]>({
      query: ([id, weeklyChallenges]) => {
        return {
          url: `/weeklyChallenges/${id}`,
          method: 'PUT',
          body: weeklyChallenges,
        }
      },
      invalidatesTags: (_result, _error, [id]) => [{ type: 'WeeklyChallenges', id }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetWeeklyChallengesQuery,
  useGetWeeklyChallengeQuery,
  useCreateWeeklyChallengeMutation,
  useDestroyWeeklyChallengeMutation,
  useUpdateWeeklyChallengeMutation,
} = weeklyChallenges;
