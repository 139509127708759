import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Card, CardHeader, Col, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ActivityArea } from '@/interfaces/ActivityAreaPick';

type ActivityAreaPickActionsCardProps = {
  activityAreas: Array<ActivityArea>;
};

const ActivityAreaPickActionsCard: FC<ActivityAreaPickActionsCardProps> = ({
  activityAreas,
}) => {
  return (
    <Row className="mb-3">
      {activityAreas.map(activityArea => (
        <Col xs={6} md={6} key={activityArea.id}>
          <Link to={`/activityAreaPick/${activityArea.id}`}>
            <Card className="font-sans-serif">
              <Card.Body className="p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <Row className=" align-items-center">
                    <span
                      className="text-white badge"
                      style={{
                        backgroundColor: activityArea.color,
                        width: '10px',
                        minWidth: 0,
                        fontSize: '11px',
                        height: '15px',
                        borderRadius: 200,
                        display: 'block',
                        marginRight: '5px',
                        marginLeft: '15px',
                      }}
                    />
                    <h5
                      style={{
                        width: 'auto',
                      }}
                      className="m-0 p-0"
                    >
                      {activityArea.name}
                    </h5>
                  </Row>
                  <FontAwesomeIcon icon={faEdit} className="mt-0 p-0 w-auto" />
                </div>
                <Col className="mt-2">
                  <Row className="align-items-center">
                    <h1
                      className="fs--1 mb-0"
                      style={{
                        width: 'auto',
                      }}
                    >
                      Max Eta :
                    </h1>
                    <span
                      style={{
                        width: 'auto',
                      }}
                      className="fs--1.5 text-500"
                    >
                      30
                    </span>
                  </Row>
                  <Row className="align-items-center">
                    <h1
                      className="fs--1 mb-0"
                      style={{
                        width: 'auto',
                      }}
                    >
                      Radius :
                    </h1>
                    <span
                      style={{
                        width: 'auto',
                      }}
                      className="fs--1.5 text-500"
                    >
                      {activityArea.radius}
                    </span>
                  </Row>
                  <Row className="align-items-center">
                    <h1
                      className="fs--1 mb-0"
                      style={{
                        width: 'auto',
                      }}
                    >
                      Offers :
                    </h1>
                    <span
                      style={{
                        width: 'auto',
                      }}
                      className="fs--1.5 text-500"
                    >
                      {activityArea.offers.map(offer => offer.name).join(' | ')}
                    </span>
                  </Row>
                </Col>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default ActivityAreaPickActionsCard;
