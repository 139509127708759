import React from 'react';
import StatsItem from './StatsItem';
import { Card, Col, Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useKpiStasQuery } from '@/services/kpiStats';

function StatsSortables() {
  const [days, setDays] = React.useState('1');

  const { data, isLoading, isError } = useKpiStasQuery({ days });

  const datas = [
    {
      title: 'Requests',
      value: data?.nbRequests,
    },
    {
      title: 'Rides completed',
      value: data?.ridesCompleted,
    },
    {
      title: 'Rides cancelled',
      value: data?.ridesCancelled,
    },
    {
      title: 'New users',
      value: data?.nbNewUsers,
    },
    {
      title: 'New drivers',
      value: data?.nbNewDrivers,
    },
    {
      title: 'Driver service online',
      value: data?.nbDriversOnline,
    },
  ];

  const onChange = (e: any) => {
    setDays(e.target.value);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError || !data) {
    return <div>Error</div>;
  }

  return (
    <Card>
      <Card.Header>
        <h5 className="mb-2">Stats</h5>
        <Form.Group controlId="timeSelect">
          <Form.Control as="select" onChange={onChange}>
            <option value="1">24 hours</option>
            <option value="3">3 days</option>
            <option value="7">7 days</option>
            <option value="14">14 days</option>
            <option value="30">30 days</option>
          </Form.Control>
        </Form.Group>
      </Card.Header>
      <Row className="px-3 rounded-2 overflow-hidden">
        {datas.map((data, i) => (
          <Col xs={6} lg={4} key={data.title} className="p-0">
            <StatsItem {...data} id={i} />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

export default StatsSortables;
