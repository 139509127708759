import DriverRequest from '../DriverRequest';
import { IFilters } from '../Fetch';
import IRequestOffer from '../RequestOffer';
import IUser from '../User';
import { IDriverRating } from './DriverRating';
import { IUserRating } from './UserRating';

export type IRideStatus =
  | 'requesting'
  | 'accepted'
  | 'driver_arrived'
  | 'started'
  | 'completed'
  | 'cancelled'
  | 'driver_cancelled'
  | 'no_drivers_available'
  | 'pending'
  | 'internal_error';

export type RideCancellationReasons =
  | 'other'
  | 'waiting_too_long'
  | 'wrong_pickup_location'
  | 'accident'
  | 'wrong_vehicle_type'
  | 'wrong_destination';

export const RIDE_CANCELLATION_REASONS: RideCancellationReasons[] = [
  'other',
  'waiting_too_long',
  'wrong_pickup_location',
  'accident',
  'wrong_vehicle_type',
  'wrong_destination',
];

export type DriverCancellationReasons =
  | 'other'
  | 'vehicle_issue'
  | 'client_asked_to_cancel'
  | 'dont_want_to_serve_client'
  | 'accident'
  | 'disagreement'
  | 'client_asked_dropoff'
  | 'client_did_not_show'
  | 'received_better_ride'
  | 'forbidden_area'
  | 'break'
  | 'too_far';

export const DRIVER_CANCELLATION_REASONS: DriverCancellationReasons[] = [
  'other',
  'vehicle_issue',
  'client_asked_to_cancel',
  'dont_want_to_serve_client',
  'accident',
  'disagreement',
  'client_asked_dropoff',
  'client_did_not_show',
  'received_better_ride',
  'forbidden_area',
  'break',
  'too_far',
];

export type AllCancellationReasons =
  | RideCancellationReasons
  | DriverCancellationReasons;

interface IRide {
  id: string;
  authorId: string;
  requestOfferId: string;
  status: IRideStatus;
  author: IUser;
  offer: IRequestOffer;
  driverArrivedAt: string | null;
  userRideRating: IUserRating;
  requestedDriversCount: number;
  cancellationReason: AllCancellationReasons;
  cancellationReasonMessage: string;
  driverRideRating: IDriverRating;
  driverRequest: DriverRequest;
  startedAt: string | null;
  completedAt: string | null;
  cancelledAt: string | null;
  createdAt: string;
  updatedAt: string;
  nbNotifiedDrivers: number;
  driverPrice: number;
  pdfLink: string| null;
  totalRidesCount: number;
}

export interface ISearchRide {
  userId?: string;
  driverId?: string;
  lat?: number;
  lng?: number;
  status?: IRideStatus;
  departureAddress?: string;
}

export interface IRideFilters extends IFilters, ISearchRide {}

export default IRide;
