import Loader from '@/components/common/Loader';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import SoftBadge from '@/components/common/SoftBadge';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';
import {
  getColorFromIsArchived,
  getReelArchivedName,
} from '@/helpers/UserHelpers';
import useLoadOptions from '@/hooks/useLoadOptions';
import { CATEGORY_OPTIONS, DISCOUNT_TYPES, ICouponForm } from '@/interfaces/Coupon';
import { useGetCouponQuery, useUpdateCouponMutation } from '@/services/coupons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

interface UpdateCouponCardProps {
  id: string;
}

const UpdateCouponCard: FC<UpdateCouponCardProps> = ({ id }) => {
  const { data, isLoading } = useGetCouponQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<ICouponForm>>({
    defaultValues: {
      discountType: DISCOUNT_TYPES[0],
      category: CATEGORY_OPTIONS.at(-1)
    },
  });
  const [updateCouponMutation] = useUpdateCouponMutation();
  const navigation = useNavigate();
  const { loadOffersOptions } = useLoadOptions();
  const [isArchived, setIsArchived] = useState<boolean>(
    data?.isArchived || false
  );

  const onSubmit: SubmitHandler<Partial<ICouponForm>> = ({
    discountType,
    excludeOffers,
    category,
    ...newData
  }) => {
    const formattedStartAt = data?.startAt
      ? DateTime.fromISO(data.startAt)
      : null;
    const formattedEndAt = data?.startAt ? DateTime.fromISO(data.endAt) : null;
    const result = getDifferenceBetweenTwoObjects<ICouponForm>(
      {
        ...data,
        startAt: formattedStartAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
        endAt: formattedEndAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
      },
      newData
    );

    toast.promise(
      updateCouponMutation([
        id,
        {
          ...result,
          category: category?.value,
          discountType: discountType?.value,
          excludeOfferIds: excludeOffers?.map(offer => offer.id),
        },
      ]).unwrap(),
      {
        pending: `Modification du coupon en cours...`,
        success: {
          render() {
            navigation(-1);

            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le coupon à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<ICouponForm>> = error =>
    console.log(error);

  useEffect(() => {
    if (data) {
      const formattedStartAt = data.startAt
        ? DateTime.fromISO(data.startAt)
        : null;
      const formattedEndAt = data.startAt ? DateTime.fromISO(data.endAt) : null;
      reset({
        ...data,
        userId: undefined,
        discountAmount: data.discountAmount,
        discountType: DISCOUNT_TYPES.find(
          element => element.value === data.discountType
        ),
        startAt: formattedStartAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
        endAt: formattedEndAt?.toFormat("yyyy-MM-dd'T'HH:mm") ?? '',
      });
    }
  }, [data]);

  const handleArchiveToggle = async () => {
    try {
      setIsArchived(prevIsArchived => !prevIsArchived);
      await updateCouponMutation([id, { isArchived: !isArchived }]);
      navigation(-1);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Edit a coupon</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Status :</Form.Label>
                <SoftBadge
                  bg={getColorFromIsArchived(data?.isArchived || false)}
                  className={'m-3 text-xl-center'}
                >
                  {getReelArchivedName(data?.isArchived || false)}
                </SoftBadge>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : NOEL10"
                  type="text"
                  {...register('name', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Min. Ride Completed <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('minRideCompleted', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.minRideCompleted}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.minRideCompleted?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Max. Ride Completed <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('maxRideCompleted', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 1,
                      message: 'Ce champ doit contenir au moins 1 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.maxRideCompleted}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxRideCompleted?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Discount Amount <span className="text-danger">*</span> :
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="ex : 10"
                    type="number"
                    {...register('discountAmount', {
                      required: 'Ce champ est requis.',
                      minLength: {
                        value: 2,
                        message:
                          'Le discount amount doit contenir au moins 2 caractères.',
                      },
                      maxLength: {
                        value: 255,
                        message:
                          'Le discount amount doit contenir au maximum 255 caractères.',
                      },
                    })}
                    isInvalid={!!errors.discountAmount}
                  />
                  <Controller
                    control={control}
                    name="discountType"
                    render={({ field }) => (
                      <InputGroup.Text>{field.value?.label}</InputGroup.Text>
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.discountAmount?.message}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Discount Type <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="discountType"
                  render={({ field }) => (
                    <Select
                      options={DISCOUNT_TYPES}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.discountAmount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Max Discount Amount € <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('maxDiscountAmount', {
                    required: 'Ce champ est requis..',
                  })}
                  isInvalid={!!errors.maxDiscountAmount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.maxDiscountAmount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Min Purchase Amount <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('minPurchaseAmount', {
                    required: 'Ce champ est requis..',
                    minLength: {
                      value: 2,
                      message:
                        'Le minPurchaseAmount doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Le minPurchaseAmount doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.minPurchaseAmount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.minPurchaseAmount?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Limit Per User <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('limitPerUser', {
                    required: 'Ce champ est requis..',
                    maxLength: {
                      value: 255,
                      message:
                        'Le limitPerUser doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.limitPerUser}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.limitPerUser?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Total Limit <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 10"
                  type="number"
                  {...register('totalLimit', {
                    required: 'Ce champ est requis..',
                    minLength: {
                      value: 2,
                      message:
                        'Le totalLimit doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Le totalLimit doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.totalLimit}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.totalLimit?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start At <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('startAt', {
                    validate: value => {
                      if (!value) return '';
                    },
                    required: 'La date de début est obligatoire',
                  })}
                  isInvalid={!!errors.startAt?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startAt?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  End At <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('endAt', {
                    validate: value => {
                      if (!value) return '';
                    },
                    required: 'La date de fin est obligatoire',
                  })}
                  isInvalid={!!errors.endAt?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endAt?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Exclude Offers <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="excludeOffers"
                  render={({ field }) => (
                    <SelectPaginateAsync
                      loadOptions={loadOffersOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                      isMulti
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.excludeOffers?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Category <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <Select
                options={CATEGORY_OPTIONS}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.category?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
            <Col className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                as="textarea"
                type="text"
                {...register('description', {
                  minLength: {
                    value: 3,
                    message: 'Ce champ doit contenir au moins 3 caractères.',
                  },
                  maxLength: {
                    value: 3000,
                    message: 'Ce champ doit contenir au plus 255 caractères.',
                  },
                })}
                style={{ minHeight: 100 }}
              />
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3 me-2"
          >
            Confirmer
          </Button>
          <Button
            variant={isArchived ? 'info' : 'danger'}
            disabled={isLoading}
            onClick={handleArchiveToggle}
            className="mt-3"
          >
            {isArchived ? 'Unarchive' : 'Archive'}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateCouponCard;
