import { IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';
import api from '@/services/api';
import IUser, { IUserFilters, IUserFormSend } from '@/interfaces/User';

export const usersApi = api.injectEndpoints({
  endpoints: build => ({
    getUsers: build.query<IPaginateResponse<IUser>, IUserFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/users',
          params,
        };
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users' as const, id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [],
    }),
    getUser: build.query<IUser, string>({
      query: id => `/users/${id}`,
      providesTags: (_user, _err, id) => [{ type: 'Users', id }],
    }),
    getUserByEmailOrPhone: build.query<IUser, { email?: string; phone?: string }>({
      query: ({ email, phone }) => ({
        url: `/users/email-or-phone`,
        params: { email, phone },
        method: 'GET',
      }),
      providesTags: (_result, _error, { email, phone }) => [
        { type: 'Users', id: `EMAIL_${email}_PHONE_${phone}` },
      ],
    }),        
    storeUser: build.mutation<IUser, IUserFormSend>({
      query: body => {
        const newBody = createFormDataRequest(body);
        return {
          url: '/users',
          method: 'POST',
          body: newBody,
        };
      },
      invalidatesTags: (_result, _err, _body) => [
        { type: 'Users', id: 'LIST' },
      ],
    }),
    updateUser: build.mutation<IUser, [string, Partial<IUserFormSend>]>({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/users/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (_user, _err, [id]) => [{ type: 'Users', id }],
    }),
    destroyUser: build.mutation<void, string>({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useLazyGetUsersQuery,
  useStoreUserMutation,
  useDestroyUserMutation,
  useLazyGetUserByEmailOrPhoneQuery
} = usersApi;
