import api from './api';
import { objectToQueryString } from '@/helpers/utils';
import { KpiStatsQuery, KpiStatsResult } from '@/interfaces/KpiStats';


export const liveActivityApi = api.injectEndpoints({
  endpoints: build => ({
    kpiStas: build.query<KpiStatsResult, KpiStatsQuery>({
      query: liveActivityQuery => {
        const params = objectToQueryString(liveActivityQuery);
        return {
          url: `/kpiStats?${params}`,
        };
      },
    }),
  }),
});

export const {
  useKpiStasQuery
} = liveActivityApi;
