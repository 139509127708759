// @ts-ignore
import ActivityAreaPickHeader from '@/components/card-headers/activityAreaPick/ActivityAreaPickHeader';
import ActivityAreaPickActionsCard from '@/components/cards/activityAreaPick/ActivityAreaPickActionsCard';
import { ActivityAreaPickMap } from '@/components/common/map/ActivityAreaPickMap';
import { useGetAllActivityAreaQuery } from '@/services/activityArea';
import { FC } from 'react';

const ActivityAreaPick: FC = () => {
  const { isLoading, data } = useGetAllActivityAreaQuery({});

  if (isLoading || !data) return <div>Loading...</div>;
  return (
    <div className="flex-1 md-h-100 ">
      <ActivityAreaPickHeader id="" />
      <div style={{ height: '70vh' }}>
        <ActivityAreaPickMap activityAreas={data} />
      </div>
      <div className="mt-2">
        <ActivityAreaPickActionsCard activityAreas={data} />
      </div>
    </div>
  );
};

export default ActivityAreaPick;
