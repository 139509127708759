import { FC } from 'react';
import { Rating, RatingProps } from 'react-simple-star-rating';

interface StarRatingProps {
  initialValue: number;
  handleChange?: (value: number) => void;
}

const StarRating: FC<StarRatingProps & RatingProps> = ({
  initialValue,
  handleChange,
  ...rest
}) => {
  return (
    <Rating
      initialValue={initialValue}
      onPointerMove={handleChange}
      size={16}
      {...rest}
    />
  );
};

export default StarRating;
