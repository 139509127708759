import Modal from '@/components/common/Modal';
import {  IMessageFormSend } from '@/interfaces/IMessage';
import { IModalMethods } from '@/interfaces/Modal';
import { useStoreMessageMutation } from '@/services/waMessages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const WaMessagesModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<IMessageFormSend>();

  const [storeMessageAsync] = useStoreMessageMutation();

  const showOrHiddenModal = () => {
    ModalRef.current?.showOrHiddenModal();
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IMessageFormSend> = (data) => {
    toast.promise(
      storeMessageAsync({...data, type: 'driver_cancelled'}).unwrap(),
      {
        pending: 'Ajout du message en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le message à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IMessageFormSend> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter un WA Message"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={12}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="Driver Cancelled"
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="mb-3">
            <Form.Label>
              Messages <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Hello, je suis un message"
              {...register('messages', {
              required: 'Ce champ est requis.',
              minLength: {
                value: 1,
                message: 'Ce champ doit contenir au moins 1 caractères.',
              },
              maxLength: {
                value: 255,
                message: 'Ce champ doit contenir au maximum 255 caractères.',
              },
              })}
              isInvalid={!!errors.messages}
            />
            <Form.Control.Feedback type="invalid">
              {errors.messages?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(WaMessagesModal);
