import api from '../api';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';
import { WeeklyFleetManager } from '@/interfaces/WeeklyFleetManager';

export const weeklyFleetmanager = api.injectEndpoints({
  endpoints: build => ({
    getWeeklyFleetManager: build.query<
      IPaginateResponse<WeeklyFleetManager>,
      [string, IFilters]
    >({
      query: ([companyId, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/companies/${companyId}/weekly-fleet-manager-report`,
          params,
        };
      },
      providesTags: ['WeeklyFleetManager'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetWeeklyFleetManagerQuery } = weeklyFleetmanager;
