import Table from '@/components/common/table/Table';
import SupplyHoursHeader from '@/components/table-headers/supplyHours/SupplyHoursHeader';
import { getLastSevenDaysDates } from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import { ISupplyHours } from '@/interfaces/ISupplyHours';
import { useLazyGetSupplyHoursQuery } from '@/services/supplyHours';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

const SupplyHours = () => {
  const [getSupplyDatasAsync, { isFetching, isLoading }] =
    useLazyGetSupplyHoursQuery();

  const [state, setState] = useState<Partial<TableState> & ISupplyHoursFilters>(
    {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
      globalFilter: '',
      date: DateTime.now().toFormat('yyyy-MM-dd'),
      sorting: [
        {
          id: 'createdAt',
          desc: true,
        },
      ],
    }
  );

  const [columns, setColumns] = useState<ColumnDef<ISupplyHours>[]>([]);
  const [rows, setRows] = useState<ISupplyHours[]>([]);

  const days = useMemo(() => {
    if (state.date) {
      return getLastSevenDaysDates(state.date);
    }
    return [];
  }, [state.date]);

  const prepareColumnsAndData = (
    result: Record<string, Record<number, number>>
  ) => {
    const columns: ColumnDef<ISupplyHoursRow>[] = [
      {
        id: 'hour',
        header: 'Hours',
        footer: (props: { column: { id: string } }) => props.column.id,
        accessorFn: (row: { hour: number }) =>
          `${String(row.hour).padStart(2, '0')}:00 - ${String(
            row.hour
          ).padStart(2, '0')}:59`,
        cell: info => info.getValue(),
      },
    ];

    Object.keys(result).forEach(date => {
      columns.push({
        id: date,
        header: DateTime.fromISO(date).toFormat('dd/MM/yyyy'),
        footer: (props: { column: { id: string } }) => props.column.id,
        accessorFn: (row: { [key: string]: number }) => row[date],
        cell: info => info.getValue(),
      });
    });

    const data: ISupplyHoursRow[] = [];

    for (let hour = 0; hour < 24; hour++) {
      const row: ISupplyHoursRow = { hour };
      Object.keys(result).forEach(date => {
        row[date] = result[date][hour] ?? 0;
      });
      data.push(row);
    }

    return { columns, data };
  };

  useEffect(() => {
    const getAllSupplyDays = async () => {
      const result: ISupplyDays = {};

      days.forEach(day => {
        result[day] = {};
        for (let hour = 0; hour < 24; hour++) {
          result[day][hour] = 0;
        }
      });

      const supplyDataArray = await Promise.all(
        days.map(async (day, index) => {
          const nowHour = DateTime.now().get('hour');

          const { data: driverData } = await getSupplyDatasAsync({
            limit: 24, // for 24 hours
            offset: index === 6 ? 0 : nowHour + 1 + index * 24, // Limit data to current hour on current day
          });

          driverData?.forEach((driver: ISupplyDriver) => {
            const driverDate = DateTime.fromISO(driver.created_at);
            const driverDay = driverDate.toFormat('yyyy-MM-dd');
            const driverHour = driverDate.get('hour');

            if (result[driverDay]) {
              result[driverDay][driverHour] = driver.nb_drivers_online;
            }
          });

          return { date: day, data: result[day] };
        })
      );

      supplyDataArray.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      const sortedResult = Object.keys(result)
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .reduce((acc, key) => {
          acc[key] = result[key];
          return acc;
        }, {} as Record<string, Record<number, number>>);

      const { columns, data } = prepareColumnsAndData(sortedResult);

      setColumns(columns as ColumnDef<ISupplyHours>[]);
      setRows(data);
    };

    getAllSupplyDays();
  }, [state.date, days]);

  return (
    <Table
      data={rows}
      columns={columns}
      HeaderComponent={SupplyHoursHeader}
      state={state}
      onStateChange={setState}
      loading={isLoading || isFetching}
      meta={{
        total: 0,
        currentPage: 0,
        perPage: 10,
        firstPage: 0,
        lastPage: 0,
        previousPageUrl: null,
        nextPageUrl: null,
        firstPageUrl: '',
        lastPageUrl: '',
      }}
    />
  );
};

export default SupplyHours;

interface ISupplyHoursFilters extends Partial<TableState>, IFilters {
  date?: string;
}

interface ISupplyDriver {
  created_at: string;
  nb_drivers_online: number;
  id: string;
}
interface ISupplyDays {
  [key: string]: Record<number, number>;
}

interface ISupplyHoursRow {
  hour: number;
  [key: string]: number;
}
