import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import WeeklyChallengeHeader from '@/components/table-headers/weeklyChallenge/WeeklyChallengeHeader';
import { splitText } from '@/helpers/utils';
import { getColorTypeWeeklyChallengeType } from '@/helpers/WeeklyChallengeHelper';
import { IFilters } from '@/interfaces/Fetch';
import { IWeeklyChallenge } from '@/interfaces/IWeeklyChallenges';
import {
  useDestroyWeeklyChallengeMutation,
  useGetWeeklyChallengesQuery,
} from '@/services/weeklyChallenges';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const WeeklyChallengesView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const [deleteWeeklyChallengeAsync] = useDestroyWeeklyChallengeMutation();

  const { data, isLoading, isError, error } = useGetWeeklyChallengesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const onDelete = useCallback((id: string) => {
    toast.promise(deleteWeeklyChallengeAsync(id).unwrap(), {
      pending: 'Suppression du challenge en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">
              Le challenge à bien été supprimé 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  const columns = useRef<ColumnDef<IWeeklyChallenge>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link
            to={`/weeklyChallenges/${id}`}
            className="btn btn-primary btn-sm"
          >
            <FontAwesomeIcon icon={faEye} />
          </Link>
          <Link
            to={`/weeklyChallenges/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Button
            variant="danger"
            size="sm"
            className="ms-2"
            onClick={() => DeleteModalRef.current?.showOrHiddenModal(id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title }) => splitText(title, 18),
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'Type',
      footer: props => props.column.id,
      accessorFn: ({ type }) => (
        <SoftBadge bg={getColorTypeWeeklyChallengeType(type)}>{type}</SoftBadge>
      ),
      id: 'type',
      cell: info => info.getValue(),
    },
    {
      header: 'Is Active',
      footer: props => props.column.id,
      accessorFn: ({ archived, startDate, endDate }) => {
        const isUptoDate =
          new Date(startDate) <= new Date() && new Date(endDate) >= new Date();
        return (
          <Badge bg="200" className="text-info fs--1">
            {!archived && isUptoDate ? 'Oui' : 'Non'}
          </Badge>
        );
      },
      id: 'is_active',
      cell: info => info.getValue(),
    },
    {
      header: 'Priority',
      footer: props => props.column.id,
      accessorFn: ({ priority }) => (
        <Badge bg="200" className="text-info fs--1">
          {priority}
        </Badge>
      ),
      id: 'priority',
      cell: info => info.getValue(),
    },
    {
      header: 'Archived',
      footer: props => props.column.id,
      accessorFn: ({ archived }) => (
        <span
          className={classNames(
            'badge border link-secondary text-decoration-none'
          )}
        >
          {archived ? 'Oui' : 'Non'}
        </span>
      ),
      id: 'archived',
      cell: info => info.getValue(),
    },
    {
      header: 'Start Date',
      footer: props => props.column.id,
      accessorFn: ({ startDate }) => (
        <>
          {DateTime.fromISO(startDate).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(startDate).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'start_date',
      cell: info => info.getValue(),
    },
    {
      header: 'End Date',
      footer: props => props.column.id,
      accessorFn: ({ endDate }) => (
        <>
          {DateTime.fromISO(endDate).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(endDate).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'end_date',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
          <p className="mb-0 text-500">
            {DateTime.fromISO(createdAt).toFormat('HH:mm')}
          </p>
        </>
      ),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le challenge qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={WeeklyChallengeHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </>
  );
};

export default WeeklyChallengesView;
