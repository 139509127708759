import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { FeatureGroup as FeatureGroupElement, useMap } from 'react-leaflet';
import { EditControl, EditControlProps } from 'react-leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import L, { FeatureGroup } from 'leaflet';
import { LeafletMapDrawerShape } from '@/interfaces/ActivityAreaPick';

export type LeafletMapDrawerProps = {
  onShape?: (shape: LeafletMapDrawerShape) => void;
  color?: string;
};

export type LeafletMapDrawerParams = {
  setShape: (shape: LeafletMapDrawerShape) => void;
};

const LeafletMapDrawerOverlay: ForwardRefRenderFunction<
  LeafletMapDrawerParams,
  LeafletMapDrawerProps
> = ({ onShape, color = 'blue' }, ref) => {
  const featureGroupRef = useRef<FeatureGroup>(null);
  const map = useMap(); // Access the map instance

  useEffect(() => {
    if (featureGroupRef.current) {
      // Change color of the rectangle
      featureGroupRef.current.eachLayer((layer: any) => {
        layer.setStyle({ color });
      });
    }
  }, [color]);

  useImperativeHandle(ref, () => ({
    setShape: (shape: LeafletMapDrawerShape) => {
      if (featureGroupRef.current) {
        featureGroupRef.current.clearLayers();
      }

      const bounds: L.LatLngBoundsExpression = [
        [shape.southWest.lat, shape.southWest.lng], // Bottom-left corner (SW)
        [shape.northEast.lat, shape.northEast.lng], // Top-right corner (NE)
      ];
      const layer = L.rectangle(bounds);

      if (onShape) {
        onShape(shape);
      }
      featureGroupRef.current?.addLayer(layer);

      // Fit the map to the bounds of the rectangle
      map.fitBounds(bounds);
    },
  }));

  const onCreated: Required<EditControlProps>['onCreated'] = e => {
    const { layerType, layer } = e;
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
    }
    
    if (layerType === 'rectangle') {
      const rectangleData = layer.toGeoJSON();
      
      if (onShape) {
        const coordinates = rectangleData.geometry.coordinates[0];
        const shape: LeafletMapDrawerShape = {
          southWest: {
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          },
          northWest: {
            lat: coordinates[1][1],
            lng: coordinates[1][0],
          },
          northEast: {
            lat: coordinates[2][1],
            lng: coordinates[2][0],
          },
          southEast: {
            lat: coordinates[3][1],
            lng: coordinates[3][0],
          },
        };
        console.log(shape);
        onShape(shape);
        const bounds: L.LatLngBoundsExpression = [
          [shape.southWest.lat, shape.southWest.lng],
          [shape.northEast.lat, shape.northEast.lng],
        ];
        map.fitBounds(bounds); // Fit map to new bounds
      }
      featureGroupRef.current?.addLayer(layer);
    }
  };

  const onEdited: Required<EditControlProps>['onEdited'] = e => {
    const { layers } = e;
    layers.eachLayer((layer: any) => {
      const editedData = layer.toGeoJSON();

      layer.setStyle({ color });
      if (onShape) {
        const coordinates = editedData.geometry.coordinates[0];
        const shape: LeafletMapDrawerShape = {
          southWest: {
            lat: coordinates[0][1],
            lng: coordinates[0][0],
          },
          northWest: {
            lat: coordinates[1][1],
            lng: coordinates[1][0],
          },
          northEast: {
            lat: coordinates[2][1],
            lng: coordinates[2][0],
          },
          southEast: {
            lat: coordinates[3][1],
            lng: coordinates[3][0],
          },
        };
        onShape(shape);
        const bounds: L.LatLngBoundsExpression = [
          [shape.southWest.lat, shape.southWest.lng],
          [shape.northEast.lat, shape.northEast.lng],
        ];
        map.fitBounds(bounds); // Fit map to edited bounds
      }
    });
  };

  return (
      <FeatureGroupElement ref={featureGroupRef}>
        <EditControl
          position="topright"
          onCreated={onCreated}
          onEdited={onEdited} // Handle when the shape is edited
          draw={{
            rectangle: true,
            circle: false,
            circlemarker: false,
            polyline: false,
            marker: false,
            polygon: false,
          }}
        />
      </FeatureGroupElement>
  );
};

export default forwardRef(LeafletMapDrawerOverlay);
