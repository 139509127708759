import IGooglePlace from './GooglePlace';
import { PlaceAutocompleteResult } from './PlaceAutocomplete';
import { ISelectOption } from './Select';
import { StripeStatus } from './Stripe';
import { GenderType } from './User';

export const CompanyCountryConst_ = [
  'FR',
  'BE',
  'LU',
  'DE',
  'ES',
  'IT',
  'PT',
  'UK',
  'US',
  'IE',
  'AM',
] as const;

export const CompanyStatusConst_ = ['pending', 'active', 'inactive', 'partially valid'] as const;

export const CompanyPersonRoleConst_ = [
  'owner',
  'executive',
  'director',
  'representative',
] as const;

export const CompanyBusinessTypeConst_ = ['individual', 'company'] as const;

export const CompanyCurrencyConst_ = ['EUR'] as const;

export type CompanyCountry = (typeof CompanyCountryConst_)[number];

export type CompanyStatus = (typeof CompanyStatusConst_)[number];

export type CompanyPersonRole = (typeof CompanyPersonRoleConst_)[number];

export type CompanyBusinessType = (typeof CompanyBusinessTypeConst_)[number];

export type CompanyCurrency = (typeof CompanyCurrencyConst_)[number];

interface Company {
  country: CompanyCountry;
  status: CompanyStatus;
  bankAccountCountry: CompanyCountry;
  bankAccountCurrency: CompanyCurrency;
  chargesEnabled: boolean;
  detailsSubmitted: boolean;
  payoutsEnabled: boolean;
  stripeAccountId: string;
  fleetCompaniesStatus: boolean;
  driverCount: number;
  id: string;
  address: CompanyAddress;
  bankAccountHolderName: string;
  bankAccountHolderType: CompanyBusinessType;
  bankAccountNumber: string;
  businessType: CompanyBusinessType;
  defaultCurrency: CompanyCurrency;
  email: string;
  name: string;
  phone: string;
  stripeBankAccountId: string;
  stripeStatus?: StripeStatus;
  stripeVerifyLink?: string;
  stripeBankAccount: IStripeBankAccount;
  supportAddress: string | null;
  supportAddressId: string | null;
  supportEmail: string | null;
  supportPhone: string | null;
  taxId: string;
  vatId: string;
  createdAt: string;
  updatedAt: string;
  owner: OwnerCompany;
}

export interface IStripeBankAccount {
  id: string;
  object: string;
  account: string;
  account_holder_name: string;
  account_holder_type: string;
  account_type: string;
  available_payout_methods: string[];
  bank_name: string;
  country: string;
  currency: string;
  default_for_currency: boolean;
}

export type CompanyAddress = IGooglePlace & {
  country: CompanyCountry;
  city: string;
  postalCode: string;
};

export interface CompanyFormCreate
  extends Omit<CompanyFormUpdate, 'supportAddress' | 'owner'> {
  supportAddress: PlaceAutocompleteResult | null;
  owner: Omit<
    CompanyFormUpdate['owner'],
    'addressId' | 'gender' | 'nationality' | 'role'
  > & {
    gender: ISelectOption<GenderType>;
    nationality: ISelectOption<CompanyCountry>;
    role: ISelectOption<CompanyPersonRole>;
    addressId: PlaceAutocompleteResult | null;
  };
}

export interface CompanyFormUpdate
  extends Omit<
    Company,
    | 'stripeAccountId'
    | 'stripeBankAccountId'
    | 'bankAccountCountry'
    | 'bankAccountCurrency'
    | 'defaultCurrency'
    | 'address'
    | 'status'
    | 'owner'
    | 'country'
    | 'businessType'
  > {
  bankAccountCurrency: ISelectOption<CompanyCurrency>;
  defaultCurrency?: ISelectOption<CompanyCurrency>;
  bankAccountCountry: ISelectOption<CompanyCountry>;
  status: ISelectOption<CompanyStatus>;
  businessType: ISelectOption<CompanyBusinessType>;
  address: PlaceAutocompleteResult | null;
  owner: Omit<
    Company['owner'],
    'addressId' | 'gender' | 'nationality' | 'role'
  > & {
    gender: ISelectOption<GenderType>;
    role: ISelectOption<CompanyPersonRole>;
    nationality: ISelectOption<CompanyCountry>;
    addressId: PlaceAutocompleteResult | null;
  };
  country: ISelectOption<CompanyCountry>;
}

export interface CompanyFormCreateSend
  extends Omit<
    Company,
    | 'stripeAccountId'
    | 'stripeBankAccountId'
    | 'bankAccountCountry'
    | 'bankAccountCurrency'
    | 'defaultCurrency'
    | 'address'
    | 'id'
    | 'supportAddress'
    | 'supportAddressId'
    | 'owner'
  > {
  addressId?: string;
  supportAddressId?: string;
  bankAccountCurrency?: CompanyCurrency;
  defaultCurrency?: CompanyCurrency;
  bankAccountCountry?: CompanyCountry;
  owner: Omit<OwnerCompany, 'gender' | 'nationality' | 'role' | 'addressId'> & {
    gender: GenderType;
    nationality: CompanyCountry;
    addressId: string;
    role: string;
  };
  country: CompanyCountry;
}

export interface CompanyFormUpdateSend
  extends DeepPartial<CompanyFormCreateSend> {}

export interface OwnerCompany {
  addressId?: string;
  address?: CompanyAddress;
  title: string;
  gender: GenderType;
  nationality: CompanyCountry;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  role: CompanyPersonRole;
  percentOwnership: number;
  birthdate: string;
}

export interface CompanyFormCreateSendForImport
  extends Omit<
    Company,
    | 'stripeAccountId'
    | 'stripeBankAccountId'
    | 'bankAccountCountry'
    | 'bankAccountCurrency'
    | 'defaultCurrency'
    | 'address'
    | 'id'
    | 'supportAddress'
    | 'supportAddressId'
    | 'owner'
  > {
  addressId?: string;
  supportAddressId?: string;
  bankAccountCurrency?: CompanyCurrency;
  defaultCurrency?: CompanyCurrency;
  bankAccountCountry?: CompanyCountry;
  owner: Omit<OwnerCompany, 'gender' | 'nationality' | 'role' | 'addressId'> & {
    gender: GenderType;
    nationality: CompanyCountry;
    addressId: string;
    role: string;
  };
  postalCode?: string;
  country: CompanyCountry;
}

export default Company;
