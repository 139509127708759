import { IPaginateResponse } from '@/interfaces/Fetch';
import api from '@/services/api';
import { IMessage, IMessageFormSend } from '@/interfaces/IMessage';

export const waMessagesApi = api.injectEndpoints({
  endpoints: build => ({
    getMessages: build.query<IPaginateResponse<IMessage>, void>({
      query: () => {
        return {
          url: '/wa-messages',
        };
      },
      providesTags: ['WaMessages'],
    }),
    getMessageById: build.query<IMessage, string>({
      query: id => `/wa-messages/${id}`,
      providesTags: ['WaMessages'],
    }),
    updateWaMessages: build.mutation<IMessage, [string, IMessageFormSend]>({
      query: ([id, data]) => {
        console.log(data);
        return {
          url: `/wa-messages/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: [{ type: 'WaMessages', id: 'LIST' }],
    }),
    storeMessage: build.mutation<IMessage, IMessageFormSend>({
      query: data => ({
        url: '/wa-messages',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'WaMessages', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMessagesQuery,
  useGetMessageByIdQuery,
  useUpdateWaMessagesMutation,
  useStoreMessageMutation,
} = waMessagesApi;
