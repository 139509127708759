import Flex from '@/components/common/Flex';
import {
  useGetDriverLastCallQuery,
  useUpdateDriverLastCallMutation,
} from '@/services/drivers/lastCall';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap/';
import { toast, ToastContentProps } from 'react-toastify';

export const DriverLastCallCard = ({ driverId }: DriverLastCallCardProps) => {
  const { data, refetch } = useGetDriverLastCallQuery(driverId);
  const [updateDriverLastCallAsync] = useUpdateDriverLastCallMutation();

  const [inputValue, setInputValue] = useState({
    lastCallDate: '',
    comment: '',
  });

  const [isEdited, setIsEdited] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue({ ...inputValue, [event.target.name]: event.target.value });
  };

  const handleReset = () => {
    setInputValue({
      lastCallDate: '',
      comment: '',
    });
  };

  const handleSubmit = () => {
    toast.promise(
      updateDriverLastCallAsync([
        driverId,
        {
          lastCallDate:
            inputValue.lastCallDate === '' ? null : inputValue.lastCallDate,
          lastCallComment: inputValue.comment,
        },
      ]),
      {
        pending: 'Modifying the column...',
        success: {
          render() {
            refetch();
            setIsEdited(false);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                The last call has been successfully modified 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            return 'An error occurred';
          },
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      const formattedDate = data.lastCallDate
        ? DateTime.fromISO(data.lastCallDate).toFormat('yyyy-MM-dd')
        : '';
      setInputValue({
        lastCallDate: formattedDate,
        comment: data.lastCallComment ?? '',
      });
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const isDateEdited = (data.lastCallDate
        ? DateTime.fromISO(data.lastCallDate).toFormat('yyyy-MM-dd')
        : '') !== inputValue.lastCallDate;
      const isCommentEdited = (data.lastCallComment ?? '') !== inputValue.comment;
      setIsEdited(isDateEdited || isCommentEdited);
    }
  }, [data, inputValue]);

  return (
    <Card className="pb-3">
      <Card.Header className="mt-0 mb-0 pb-0">
        <Flex className="justify-content-between">
          <h4 className="mb-0 pb-0">Last call</h4>
        </Flex>

        <div className="container mt-3">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Last call date</Form.Label>
              <Form.Control
                type="date"
                name="lastCallDate"
                value={inputValue.lastCallDate}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>
          </Form>
        </div>
        <div className="container mt-2">
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Comment"
                name="comment"
                value={inputValue.comment}
                onChange={handleChange}
                className="mb-3"
              />
            </Form.Group>
            {isEdited && (
              <>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="me-2"
                >
                  Sauvegarder
                </Button>
                <Button
                  variant="primary"
                  onClick={handleReset}
                  className="me-2"
                >
                  Supprimer
                </Button>
              </>
            )}
          </Form>
        </div>
      </Card.Header>
    </Card>
  );
};

interface DriverLastCallCardProps {
  driverId: string;
}
