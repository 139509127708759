import { CellContext } from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface IProps {
  cellProps: CellContext<any, unknown>;
  type?: string;
  onUpdate: (rowIndex: string, columnId: string, value: any) => void;
}

const UpdatableToggleCell: FC<IProps> = ({
  cellProps: {
    getValue,
    row: {
      original: { id: index },
    },
    column: { id },
  },
  type = 'checkbox',  // default to 'checkbox' since this is now a toggle
  onUpdate,
}) => {
  // Assuming initialValue will now be a boolean for the toggle
  const initialValue = getValue() as boolean;
  const [checked, setChecked] = useState(initialValue);

  // Handle toggle change
  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);
    onUpdate(index.toString(), id, newChecked);  // Call the update function immediately on change
  };

  // Update checked value if external data changes (in case of external updates to the table)
  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return (
    <Form.Check
      type="switch" // Use 'switch' to render a toggle switch
      id={`toggle-${index}-${id}`}
      checked={checked}
      onChange={_onChange}
    />
  );
};

export default UpdatableToggleCell;
