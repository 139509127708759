import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import { IUserFilters } from '@/interfaces/User';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useGetMessagesQuery } from '@/services/waMessages';
import { IMessage } from '@/interfaces/IMessage';
import WaMessagesHeader from '@/components/table-headers/waMessages/WaMessagesHeader';

const WaMessagesView: FC = () => {

  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error, isFetching } = useGetMessagesQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const columns = useRef<ColumnDef<IMessage>[]>([
    {
      header: 'Actions',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ id }) => (
        <>
          <Link
            to={`/waMessages/${id}/edit`}
            className="btn btn-info btn-sm ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
        </>
      ),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Title',
      footer: props => props.column.id,
      accessorFn: ({ title }) => <p className="mb-0">{title}</p>,
      id: 'title',
      cell: info => info.getValue(),
    },
    {
      header: 'Messages',
      footer: props => props.column.id,
      accessorFn: ({ messages }) => <>{messages}</>,
      id: 'startAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ created_at }) =>
        DateTime.fromISO(created_at).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <Table
        HeaderComponent={WaMessagesHeader}
        columns={columns}
        state={state}
        tableName="wa-messages"
        onStateChange={setState}
        loading={isLoading || isFetching}
        data={data?.data || []}
      />
    </div>
  );
};

export default WaMessagesView;
