import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import {
  useGetAllFeaturesQuery,
  useUpdateFeatureMutation,
} from '@/services/featuresTrigger';
import { ColumnDef } from '@tanstack/react-table';
import { FC, useRef } from 'react';
import Table from '@/components/common/table/Table';

import { DateTime } from 'luxon';
import { FeatureTriggerT } from '@/interfaces/FeatureTrigger';
import UpdatableToggleCell from '@/components/common/table/UpdatableToggleCell';
import AllFeaturesTrigger from '@/components/table-headers/featuresTrigger/AllFeaturesTrigger';

const FeatureTriggerView: FC = () => {
  const { data, isLoading, isError, error } = useGetAllFeaturesQuery();
  const [updateFeatureMutation] = useUpdateFeatureMutation();

  const onUpdate = async (id: string, _: string, factor: unknown) => {
    await updateFeatureMutation([factor as boolean, id]);
  };

  const columns = useRef<ColumnDef<FeatureTriggerT>[]>([
    {
      header: 'Feature Name',
      footer: props => props.column.id,
      accessorFn: ({ featureName }) => featureName,
      id: 'featureName',
      cell: info => info.getValue(),
    },
    {
      header: 'Description',
      footer: props => props.column.id,
      accessorFn: ({ description }) => description,
      id: 'description',
      cell: info => info.getValue(),
    },
    {
      header: 'Enabled',
      footer: props => props.column.id,
      cell: cell => (
        <UpdatableToggleCell cellProps={cell} onUpdate={onUpdate} type="" />
      ),
      accessorFn: ({ enabled }) => enabled,
    },
    {
      header: 'Created At',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) =>
        DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <Table
        HeaderComponent={AllFeaturesTrigger}
        columns={columns}
        data={data ?? []}
        onStateChange={() => {}}
        state={{}}
      />
    </div>
  );
};

export default FeatureTriggerView;
