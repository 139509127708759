import { IRoute } from '@/interfaces/Route';
import { faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faBalanceScale,
  faBuilding,
  faCalendarDay,
  faCar,
  faCarSide,
  faEnvelope,
  faEuro,
  faEye,
  faHandshake,
  faIdCard,
  faMapLocation,
  faMoneyBill,
  faNewspaper,
  faPhone,
  faStar,
  faTags,
  faTaxi,
  faToggleOff,
  faTrophy,
  faTruck,
  faUsers,
  faUsersViewfinder,
} from '@fortawesome/free-solid-svg-icons';

export const PUBLIC_ROUTES = [
  '/login',
  '/forgotPassword',
  '/verificationOtp',
  '/register',
];

export const adminRoutes: IRoute = {
  label: 'User Management',
  roles: ['superadmin'],
  children: [
    {
      name: 'Users',
      icon: faUsersViewfinder,
      href: '/users',
    },
    {
      name: 'Drivers',
      icon: faTruck,
      href: '/drivers',
    },
    {
      name: 'Companies',
      icon: faBuilding,
      href: '/companies',
    },
    {
      name: 'Vehicles',
      icon: faCarSide,
      href: '/vehicles',
    },
  ],
};

export const homeRoutes: IRoute = {
  label: 'Home',
  roles: ['superadmin'],
  children: [
    {
      name: 'Live Activity View',
      icon: faEye,
      href: '/liveActivityMap',
    },
    {
      name: 'Supply hours',
      icon: faCalendarDay,
      href: '/supplyHours',
    },
    {
      name: 'Available Areas',
      icon: faMapLocation,
      href: '/activityAreaPick',
    },
    {
      name: 'Features trigger',
      icon: faToggleOff,
      href: '/featuresTrigger',
    },
  ],
};

export const ridesRoutes: IRoute = {
  label: 'Rides Management',
  roles: ['superadmin'],
  children: [
    {
      name: 'Requests',
      icon: faCar,
      href: '/rides',
    },
    {
      name: 'Searches',
      icon: faTaxi,
      href: '/requests',
    },
  ],
};

export const configRoutes: IRoute = {
  label: 'Configuration',
  roles: ['superadmin', 'admin'],
  children: [
    {
      name: 'Price Controllers',
      icon: faEuro,
      href: '/priceControllers',
    },
    {
      name: 'Day and Time Factor',
      icon: faCalendarDay,
      href: '/dayAndTimeFactor',
    },
    {
      name: 'Offers',
      icon: faMoneyBill,
      href: '/offers',
    },
    {
      name: 'Simulate Offers',
      icon: faMapLocation,
      href: '/simulatePrice',
    },
    {
      name: 'Driving Preferences',
      icon: faIdCard,
      href: '/drivingPreferences',
    },
    {
      name: 'Weekly Challenges',
      icon: faMoneyBill,
      href: '/weeklyChallenges',
    },
    {
      name: 'User to User referal',
      icon: faUsers,
      href: '/userToUserReferal',
    }
  ],
};

export const discountRoutes: IRoute = {
  label: 'Discount & Points System',
  roles: ['superadmin', 'admin'],
  children: [
    {
      name: 'Coupons',
      icon: faTags,
      href: '',
      children: [
        {
          name: 'COMIN coupons',
          href: '/coupons',
        },
        {
          name: 'Rewards coupons',
          href: '/couponsReward',
        }
      ]
    },
    {
      name: 'Rewards',
      icon: faTrophy,
      href: '/rewards',
    },
    {
      name: 'Earn points',
      icon: faStar,
      href: '/earnPoints',
    },
    {
      name: 'Drivers Club',
      icon: faCarSide,
      href: '/driverClubs',
    },
    {
      name: 'Associations',
      icon: faMoneyBillAlt,
      href: '/associations',
    },
    {
      name: 'Partnerships',
      icon: faHandshake,
      href: '/partnerships',
    },
  ],
};

export const marketingRoutes: IRoute = {
  label: 'Marketing',
  roles: ['superadmin', 'admin'],
  children: [
    {
      name: 'Articles',
      icon: faNewspaper,
      href: '/articles',
    },
    {
      name: 'Governance',
      icon: faBalanceScale,
      href: '/governances',
    },
    {
      name: 'WA Messages',
      icon: faEnvelope,
      href: '/waMessages',
    }
  ],
};

export const otpRoutes: IRoute = {
  label: 'OTPs',
  children: [
    {
      name: 'PhoneOtps',
      icon: faPhone,
      href: '/phone-otps',
    },
    {
      name: 'Email OTP',
      icon: faEnvelope,
      href: '/email-otps',
    },
  ],
};

const toExport = [
  homeRoutes,
  adminRoutes,
  ridesRoutes,
  configRoutes,
  discountRoutes,
  marketingRoutes,
  otpRoutes,
];

export default toExport;
