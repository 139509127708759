import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { IModalMethods } from '@/interfaces/Modal';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

import {
  IWeeklyChallengeFormCreate,
  WeeklyChallengeTYPE,
} from '@/interfaces/IWeeklyChallenges';
import classNames from 'classnames';
import DatePicker from '@/components/common/DatePicker';
import { useCreateWeeklyChallengeMutation } from '@/services/weeklyChallenges';

const StoreWeeklyChallengeModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IWeeklyChallengeFormCreate>();

  const [createWeeklyChallengeMutation] = useCreateWeeklyChallengeMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IWeeklyChallengeFormCreate> = ({
    ...newData
  }) => {
    toast.promise(
      createWeeklyChallengeMutation({
        ...newData,
        startDate: (newData.startDate as unknown as Date).toISOString(),
        endDate: (newData.endDate as unknown as Date).toISOString(),
        type: newData.type.value,
      }).unwrap(),
      {
        pending: `Création du challenge en cours...`,
        success: {
          render() {
            ModalRef?.current?.showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le challenge à bien été créer 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IWeeklyChallengeFormCreate> = error =>
    console.log(error);

  return (
    <Modal
      ref={ModalRef}
      title={'Ajouter un challenge'}
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Type :</Form.Label>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  options={WeeklyChallengeTYPE}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback
              type="invalid"
              className={classNames({
                'd-block': !!errors.type,
              })}
            >
              {errors.type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Number <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 2"
              type="number"
              {...register('number', {
                required: 'Ce champ est requis.',
                min: {
                  value: 0,
                  message: 'Ce champ doit contenir au minimum 0.',
                },
              })}
              isInvalid={!!errors.number}
            />
            <Form.Control.Feedback type="invalid">
              {errors.number?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Priority <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 1"
              type="number"
              {...register('priority', {
                required: 'Ce champ est requis.',
                min: {
                  value: 0,
                  message: 'Ce champ doit contenir au minimum 0.',
                },
              })}
              isInvalid={!!errors.priority}
            />
            <Form.Control.Feedback type="invalid">
              {errors.priority?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3 d-flex flex-column">
            <Form.Label>
              Start Date <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePicker
                  value={field.value as string}
                  showTimeSelect
                  timeFormat="HH:mm"
                  onChange={field.onChange}
                  required
                />
              )}
            />

            <Form.Control.Feedback type="invalid">
              {errors.startDate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3 d-flex flex-column">
            <Form.Label>
              End Date <span className="text-danger">*</span> :
            </Form.Label>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  showTimeSelect
                  timeFormat="HH:mm"
                  onChange={field.onChange}
                  required
                />
              )}
            />

            <Form.Control.Feedback type="invalid">
              {errors.endDate?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Archived <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Check
              type="checkbox"
              {...register('archived')}
              isInvalid={!!errors.archived}
            />
            <Form.Control.Feedback type="invalid">
              {errors.archived?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Title <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : La paix est véridique."
              type="text"
              {...register('title', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreWeeklyChallengeModal);
