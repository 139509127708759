import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import StarRating from '@/components/common/StarRating';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AllRidesHeader from '@/components/table-headers/rides/AllRidesHeader';
import { GOOGLEMAPS_COORD_LINK } from '@/constants/places';
import {
  getAllCancellationReasonName,
  getColorFromCancellationReason,
  getColorFromRideStatus,
  getReelRideStatus,
} from '@/helpers/UserHelpers';
import {
  calculateAcceptanceTime,
  calculateFinalETA,
  calculateMinutesDifference,
} from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import IRide, { IRideStatus } from '@/interfaces/Ride';
import { useGetRidesQuery } from '@/services/rides';
import { useGetMessagesQuery } from '@/services/waMessages';
import { faEye, faMessage, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const RidesView: FC = () => {
  const [_, setRidesData] = useState<IRide[]>([]);

  const [state, setState] = useState<IRideFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 150,
    },
    globalFilter: '',
  });

  const truncate = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const { data, isLoading, isError, error, isFetching } = useGetRidesQuery({
    ...state.pagination,
    query: state.globalFilter,
    status: state.status,
    departureAddress: state.departureAddress,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const totalCompanies = data?.meta?.total;

  const {data: waMessages} = useGetMessagesQuery()

  useEffect(() => {
    if (data) {
      setRidesData(data.data || []);
    }
  }, [data]);

  //Calculate final ETA
  const calculateFinalETARide = (ride: IRide) => {
    const driverArrivalTime = ride.driverArrivedAt
      ? new Date(ride.driverArrivedAt).getTime()
      : 0;
    const rideCompletionTime = ride.completedAt
      ? new Date(ride.completedAt).getTime()
      : 0;
    const initialDriverEta = ride.offer.initialDriverEta;

    return calculateFinalETA(
      new Date(driverArrivalTime),
      new Date(rideCompletionTime),
      initialDriverEta
    );
  };

  const buildWaMessageLink = (phone: string) => {
    const messageContent = waMessages?.data.at(-1)?.messages;
    return `https://wa.me/${phone.replace('+', '')}?text=${messageContent}`;
  }

  const columns: ColumnDef<IRide>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <Link to={`/rides/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalCompanies === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber =
            totalCompanies - (pageIndex * pageSize + rowIndex);

          // Ensure record number is within valid range
          return recordNumber > 0
            ? recordNumber
            : totalCompanies - rowIndex - pageIndex * pageSize;
        },
      },
      {
        header: 'Date\nTime',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ createdAt }) => (
          <>
            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(createdAt).toFormat('HH:mm:ss')}
            </p>
          </>
        ),
        id: 'createdAt',
        cell: info => info.getValue(),
      },
      {
        header: 'User Name',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({
          author: { id, fullname, avatar, firstname, email },
        }) => (
          <>
            <Link to={`/users/${id}`}>
              <Flex alignItems="center">
                {avatar && (
                  <Avatar
                    src={avatar.link}
                    alt={`${firstname} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                )}
                <Flex direction="column">
                  <span>{fullname}</span>
                  <span className="mb-0 text-500">{truncate(email, 25)}</span>
                </Flex>
              </Flex>
            </Link>
          </>
        ),
        id: 'author',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '13rem', maxWidth: '20rem' },
          },
          cellProps: {
            style: { minWidth: '13rem', maxWidth: '20rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'From\nTo',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: (ride: IRide) => {
          const originAddress = ride?.offer?.request?.origin?.address || '';
          const destinationAddress =
            ride?.offer?.request?.destination?.address || '';

          const formattedOrigin = originAddress
            ? truncate(originAddress.replace(/, France$/, ''), 45)
            : 'N/A';
          const formattedDestination = destinationAddress
            ? truncate(destinationAddress.replace(/, France$/, ''), 45)
            : 'N/A';

          return (
            <>
              {formattedOrigin}
              <p className="mb-0 text-500">{formattedDestination}</p>
            </>
          );
        },
        id: 'origin',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '25rem', maxWidth: '35rem' },
          },
          cellProps: {
            style: { minWidth: '25rem', maxWidth: '35rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Start Time\nEnd Time',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ driverArrivedAt, completedAt }) => (
          <>
            {driverArrivedAt ? (
              DateTime.fromISO(driverArrivedAt).toFormat('HH:mm')
            ) : (
              <UnknownBadge />
            )}
            {completedAt ? (
              <p className="mb-0 text-500">
                {DateTime.fromISO(completedAt).toFormat('HH:mm')}
              </p>
            ) : (
              <UnknownBadge />
            )}
          </>
        ),
        id: 'driverArrivedAt',
        cell: info => info.getValue(),
      },
      {
        header: 'Ride Status\nPayment Status',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ status }) => {
          const statusText = getReelRideStatus(status);
          const statusColor = getColorFromRideStatus(status);
          if (status === 'driver_cancelled') {
            return (
              <SoftBadge bg={statusColor} textColor="#FFD700">
                {' '}
                {statusText}
              </SoftBadge>
            );
          } else {
            return <SoftBadge bg={statusColor}>{statusText}</SoftBadge>;
          }
        },
        id: 'status',
        cell: info => info.getValue(),
      },
      {
        header: 'Nb Notif\nDrivers',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ nbNotifiedDrivers }) => nbNotifiedDrivers ?? 'N/A',
        id: 'nbNotifiedDrivers',
      },
      {
        header: 'Base Price \nSug. Price',
        id: 'suggested.price',
        enableSorting: false,
        footer: props => props.column.id,
        cell: info => info.getValue(),
        accessorFn: ({ offer: { originalPrice, suggestedPrice } }) => {
          if (!suggestedPrice) {
            return 'N/A';
          }

          const variation = Math.round(
            (originalPrice / suggestedPrice - 1) * 100
          );

          if (suggestedPrice === originalPrice) {
            return (
              <>
                <p className="mb-0">
                  {originalPrice?.toFixed(2)}€{' '}
                  <span
                    style={{
                      backgroundColor: 'lightgrey',
                      color: 'grey',
                      padding: '2px',
                      borderRadius: '5px',
                    }}
                  >
                    {variation}%
                  </span>
                </p>
                {suggestedPrice?.toFixed(2)}€
              </>
            );
          } else if (suggestedPrice < originalPrice) {
            return (
              <>
                <p className="mb-0">
                  {originalPrice?.toFixed(2)}€{' '}
                  <span
                    style={{
                      backgroundColor: 'lightgreen',
                      color: 'green',
                      padding: '2px',
                      borderRadius: '5px',
                    }}
                  >
                    +{variation}%
                  </span>
                </p>
                {suggestedPrice?.toFixed(2)}€
              </>
            );
          } else if (suggestedPrice > originalPrice) {
            return (
              <>
                <p className="mb-0">
                  {originalPrice?.toFixed(2)}€{' '}
                  <span
                    style={{
                      backgroundColor: 'lightcoral',
                      color: 'white',
                      padding: '2px',
                      borderRadius: '5px',
                    }}
                  >
                    {variation}%
                  </span>
                </p>
                {suggestedPrice?.toFixed(2)}€
              </>
            );
          }
        },
      },
      {
        header: 'Base Price\nFinal Price',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ offer: { price, originalPrice } }) => (
          <>
            <p className="mb-0 text-500">{`${originalPrice}€`}</p>
            {`${price}€`}
          </>
        ),
        id: 'offer.price',
        cell: info => info.getValue(),
      },
      {
        header: 'Coupon Name\nAmount',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ offer: { couponDiscountAmount, userCoupon } }) => (
          <div className="text-center">
            {userCoupon && userCoupon.coupon ? (
              <div className="text-secondary">
                {userCoupon.coupon.name ? (
                  <SoftBadge bg="#cddff7" textColor="#1b477e">
                    {userCoupon.coupon.name}
                  </SoftBadge>
                ) : (
                  <SoftBadge bg="#ccffcc" textColor="#008000">
                    {`Reward / €${Math.floor(
                      userCoupon.coupon.discountAmount / 100
                    )}`}
                  </SoftBadge>
                )}
              </div>
            ) : (
              <UnknownBadge />
            )}
            <p>
              {couponDiscountAmount ? (
                `${couponDiscountAmount}€`
              ) : (
                <UnknownBadge />
              )}
            </p>
          </div>
        ),
        id: 'offer.couponDiscountAmount',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver Price',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ offer: { driverPrice } }) =>
          driverPrice ? `${driverPrice}€` : 'N/A',
        id: 'offer.driverPrice',
      },
      {
        header: 'Initial ETA\nFinal ETA',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: (ride: IRide) => {
          const finalEta = calculateFinalETARide(ride);
          return (
            <>
              {`${ride.offer.initialDriverEta}m`}
              <p className="mb-0 text-500">{`${finalEta}m`}</p>
            </>
          );
        },
        id: 'initialDriverEta',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '5rem', maxWidth: '5rem' },
          },
          cellProps: {
            style: { minWidth: '5rem', maxWidth: '5rem' },
          },
        },
      },
      {
        header: 'Ride Time(e)\nRide Distance(e)',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ offer: { request } }) => {
          return (
            <>
              {`${request.initialEta}min`}{' '}
              <p className="mb-0 text-500">{`${request.initialDistance}km`}</p>
            </>
          );
        },
        id: 'distance_',
        cell: info => info.getValue(),
      },

      {
        header: 'Ride Duration\nSession Duration',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({
          driverArrivedAt,
          completedAt,
          createdAt,
          updatedAt,
        }) => {
          return driverArrivedAt && completedAt ? (
            <>
              {`${calculateMinutesDifference(driverArrivedAt, completedAt)}m`}
              <p className="mb-0 text-500">
                {calculateAcceptanceTime(
                  new Date(createdAt),
                  new Date(updatedAt)
                )}
              </p>
            </>
          ) : (
            <UnknownBadge />
          );
        },
        id: 'distance',
        cell: info => info.getValue(),
      },

      // {
      //   header: 'Price Review\nFinal Price Review',
      //   enableSorting: false,
      //   footer: props => props.column.id,
      //   accessorFn: ({ offer: { price, originalPrice } }) => (
      //     <>
      //       {`${price}€`}
      //       <p className="mb-0 text-500">{`${originalPrice}€`}</p>
      //     </>
      //   ),
      //   id: 'distance',
      //   cell: info => info.getValue(),
      // },

      {
        header: 'Category',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ offer }) => offer?.offer?.name ?? 'N/A',
        id: 'category',
      },
      {
        header: 'Time &\nDist. to P.U.',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: (ride: IRide) => {
          const initialDuration = ride.driverRequest
            ? ride.driverRequest.initialDuration || 'N/A'
            : 'N/A';
          const initialDistance = ride.driverRequest
            ? ride.driverRequest.initialDistance || 'N/A'
            : 'N/A';
          return (
            <>
              {`${initialDuration}`}{' '}
              <p className="mb-0 text-500">{`${initialDistance}`}</p>
            </>
          );
        },
        id: 'pickupDetails',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: (ride: IRide) => {
          const driverRequest = ride?.driverRequest;
          const driverProfile = driverRequest?.driverService?.driverProfile;
          const user = driverProfile?.user;

          if (user) {
            const { avatar, firstname, lastname } = user;
            const driverProfileId = driverProfile.id;
            return (
              <Link to={`/drivers/${driverProfileId}`}>
                <Flex alignItems="center">
                  {avatar?.link && (
                    <Avatar
                      src={avatar.link}
                      alt={`${firstname} Avatar`}
                      className="me-2"
                      size="2xl"
                    />
                  )}
                  <span>{`${firstname} ${lastname}`}</span>
                </Flex>
              </Link>
            );
          }
          return 'N/A';
        },
        id: 'driverName',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '15rem', maxWidth: '25rem' },
          },
          cellProps: {
            style: { minWidth: '15rem', maxWidth: '25rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Last Location',
        footer: props => props.column.id,
        accessorFn: ({ driverRequest }) => {
          if (!driverRequest?.driverService?.driverProfile) return 'N/A';
          const { longitude, latitude, updatedAt } =
            driverRequest.driverService?.driverProfile;
          if (!longitude || !latitude) return 'N/A';
          return (
            <div>
              <div>{dayjs(updatedAt).format('DD/MM/YYYY hh:mm')}</div>
              <Link to={GOOGLEMAPS_COORD_LINK(latitude, longitude)}>
                localisation google
              </Link>
            </div>
          );
        },
        id: 'services.lastLocation',
        cell: info => info.getValue(),
      },
      {
        header: 'Wa Mess.',
        footer: props => props.column.id,
        accessorFn: (ride: IRide) => {
          const driverRequest = ride?.driverRequest;
          const driverProfile = driverRequest?.driverService?.driverProfile;
          const user = driverProfile?.user;

          if (user) {
            return (
              <>
                <Link to={`https:/wa.me/${user.phone.replace('+', '')}`} className='mr-2'>
                  <FontAwesomeIcon icon={faPhone} />
                </Link>
                <Link to={buildWaMessageLink(user.phone)}>
                  <FontAwesomeIcon icon={faMessage} />
                </Link>
              </>
            );
          }
          return 'N/A';
        },
        id: 'waMessages',
        cell: info => info.getValue(),
      },
      {
        header: 'Car Brand\nCar plate',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: (ride: IRide) => {
          const driverRequest = ride?.driverRequest;
          const driverVehicle = driverRequest?.driverService?.driverVehicle;
          const vehicle = driverVehicle?.vehicle;

          if (vehicle) {
            const { id, brand, model, avatar } = vehicle;
            const driverProfileId = driverVehicle?.driverProfileId;
            const licensePlate = driverVehicle?.licensePlate;
            return (
              <Link to={`/drivers/${driverProfileId}/vehicles/${id}`}>
                <div className="d-flex align-items-center">
                  {avatar?.link && (
                    <div className="me-2">
                      <Avatar
                        src={avatar.link}
                        alt={`${brand} Avatar`}
                        className="me-2"
                        size="2xl"
                      />
                    </div>
                  )}

                  <div>
                    <div>
                      <span>
                        {brand} {model}
                      </span>
                    </div>
                    <div>
                      <span>{licensePlate}</span>
                    </div>
                  </div>
                </div>
              </Link>
            );
          }
          return 'N/A';
        },
        id: 'CarBrand',
        cell: info => info.getValue(),
        meta: {
          cellProps: {
            style: { minWidth: '15rem', maxWidth: '20rem' },
            className: 'text-wrap',
          },
          headerProps: {
            style: { minWidth: '15rem', maxWidth: '20rem' },
          },
        },
      },
      {
        header: 'Company',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: (ride: IRide) => {
          const driverService = ride?.driverRequest?.driverService;

          const stripeValue = driverService?.company?.stripeId ? (
            <Link
              to={`https://connect.stripe.com/connect/accounts/${driverService?.company?.stripeId}/activity`}
            >
              {driverService?.company?.stripeId}
            </Link>
          ) : (
            'N/A'
          );

          return (
            <Flex direction="column">
              <div>{driverService?.company?.companyName ?? 'N/A'}</div>
              <div>{stripeValue}</div>
            </Flex>
          );
        },
        id: 'companyName',
        cell: info => info.getValue(),
      },
      {
        header: 'User Rating\nDriver Rating',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ userRideRating, driverRideRating }) => {
          return (
            <>
              <div>
                {userRideRating?.rating !== null &&
                userRideRating?.rating !== undefined ? (
                  <StarRating initialValue={userRideRating.rating} />
                ) : (
                  'No rating yet'
                )}{' '}
              </div>

              <div>
                {driverRideRating?.rating !== null &&
                driverRideRating?.rating !== undefined ? (
                  <StarRating initialValue={driverRideRating.rating} />
                ) : (
                  'No rating yet'
                )}{' '}
              </div>
            </>
          );
        },
        id: 'userRideRating',
        cell: info => info.getValue(),
      },
      {
        header: 'Cancellation\nReason',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: (ride: IRide) => (
          <SoftBadge
            bg={getColorFromCancellationReason(ride.cancellationReason)}
            className={'my-2 text-xl-center'}
          >
            {getAllCancellationReasonName(ride.cancellationReason)}
          </SoftBadge>
        ),
        id: 'cancellationReason',
        cell: info => info.getValue(),
      },
      {
        header: 'Cancellation\nReason Message',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: (ride: IRide) =>
          ride.cancellationReasonMessage
            ? truncate(ride.cancellationReasonMessage, 50)
            : 'N/A',
        id: 'cancellationReasonMessage',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '18rem', maxWidth: '35rem' },
          },
          cellProps: {
            style: { minWidth: '18rem', maxWidth: '35rem' },
            className: 'text-wrap',
          },
        },
      },
      // {
      //   header: 'Payment Failed\nReason',
      //   enableSorting: false,
      //   footer: props => props.column.id,
      //   accessorFn: ({ driverRequest: { price, originalPrice } }) => (
      //     <>
      //       10%
      //       <br />
      //       2.05 €
      //     </>
      //   ),
      //   id: 'commission',
      //   cell: info => info.getValue(),
      // },

      {
        header: 'Crée le',
        footer: props => props.column.id,
        accessorFn: ({ createdAt }) =>
          DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
        id: 'createdAt_',
        cell: info => info.getValue(),
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Table
      HeaderComponent={AllRidesHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      loading={isLoading || isFetching}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default RidesView;

interface IRideFilters extends Partial<TableState>, IFilters {
  status?: IRideStatus;
  departureAddress?: string;
}
