import { ITableHeaderComponentProps } from '@/interfaces/Table';
import {faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';

const AllFeaturesTrigger: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Features trigger</h6>
        </Col>
      </Row>
    </div>
  );
};

export default AllFeaturesTrigger;
