import { ILastCall } from '@/interfaces/LastCall';
import api from '@/services/api';

export const driverLastCallApi = api.injectEndpoints({
  endpoints: build => ({
    getDriverLastCall: build.query<ILastCall, string>({
      query: id => {
        return {
          url: `/drivers/${id}/lastCall`,
        };
      },
    }),
    updateDriverLastCall: build.mutation<ILastCall, [string, any]>({
      query: ([id, body]) => {
        return {
          url: `/drivers/${id}/lastCall`,
          method: 'PUT',
          body,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetDriverLastCallQuery, useUpdateDriverLastCallMutation } =
  driverLastCallApi;
