import GoogleMap from '@/components/common/map/GoogleMaps';
import { FC, useState } from 'react';
import { Rectangle, InfoWindow } from '@react-google-maps/api';
import { ActivityArea } from '@/interfaces/ActivityAreaPick';

type ActivityAreaPickActionsCardProps = {
  activityAreas: Array<ActivityArea>;
};

export const ActivityAreaPickMap: FC<ActivityAreaPickActionsCardProps> = ({
  activityAreas: data
}) => {
  const initialCenter = {
    lat: 48.8608685,
    lng: 2.34,
  };


  const [infoWindowPosition, setInfoWindowPosition] =
    useState<google.maps.LatLngLiteral & ActivityArea | null>(null);


  const handleRectangleClick = (e: google.maps.MapMouseEvent, activityArea: ActivityArea) => {
    if (e.latLng) {
      setInfoWindowPosition({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        ...activityArea,
      });
    }
  };

  return (
    <div
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        height: '100%',
        minHeight: '30vh',
      }}
      className="position-relative"
    >
      <GoogleMap
        initialCenter={initialCenter}
        options={{
          zoomControlOptions: {
            position: 0,
          },
          disableDefaultUI: true,
          zoomControl: false,
          streetViewControl: false,
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ],
        }}
        positions={isLoaded =>
          !isLoaded
            ? []
            : [
                ...(data ?? [])?.map((e, i) => (
                  <Rectangle
                    key={i}
                    bounds={{
                      north: e.coordinates.northEast.lat,
                      south: e.coordinates.southWest.lat,
                      east: e.coordinates.northEast.lng,
                      west: e.coordinates.southWest.lng,
                    }}
                    options={{
                      fillColor: e.color, // Use a visible fill color
                      fillOpacity: 0.35,
                      strokeWeight: 2,
                      strokeColor: e.color,
                    }}
                    onClick={(evt) => handleRectangleClick(evt, e)}
                  />
                )),
              ].concat(
                infoWindowPosition ? (
                  <InfoWindow
                    key={infoWindowPosition.id}
                    position={infoWindowPosition}
                    onCloseClick={() => setInfoWindowPosition(null)}
                  >
                    <div className="bg-white m-0" style={{
                      minWidth: 200,
                    }}>
                      <h4>{infoWindowPosition.name}</h4>
                      <p className='m-0'>MaxETA: {infoWindowPosition.maxETA}</p>
                      <p className='m-0'>Radius: {infoWindowPosition.radius}</p>
                    </div>
                  </InfoWindow>
                ) : (
                  []
                )
              )
        }
      />
    </div>
  );
};
