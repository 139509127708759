import UserHeader from '@/components/card-headers/users/UserHeader';
import UserActivitiesCard from '@/components/cards/users/UserActivitiesCard';
import UserCouponsCard from '@/components/cards/users/UserCouponsCard';
import UserDetailsCard from '@/components/cards/users/UserDetailsCard';
import UserPointTransactionsCard from '@/components/cards/users/UserPointTransactionsCard';
import UserReferralsTable from '@/components/cards/users/UserReferralsTable';
import UserRidesTable from '@/components/cards/users/UserRidesTable';
import UserAddressesCard from '@/components/cards/users/addresses/UserAddressesCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import IUser from '@/interfaces/User';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const UserView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser>();
  const [error, setError] = useState<FetchBaseQueryError | SerializedError>();

  if (!id) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  } else if (error) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      {user && (
        <UserHeader id={id} showTransformDriver={user.driverProfile === null} />
      )}
      <Row className="g-3 mb-3">
        <Col xxl={6}>
          <UserDetailsCard
            id={id}
            card
            callBack={setUser}
            errorCallBack={setError}
          />
        </Col>
        {user && (
          <Col xxl={6}>
            <UserActivitiesCard id={id} />
          </Col>
        )}
      </Row>
      {user && (
        <>
          <Row className='g-3 mb-3'>
            <Col xxl={6}>
              <UserReferralsTable id={user.id} />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col xxl={6}>
              <UserAddressesCard id={id} />
            </Col>
            <Col xxl={6}>
              <UserCouponsCard id={id} />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col lg={5} xxl={3}>
              <UserPointTransactionsCard id={id} />
            </Col>
            <Col xxl={9} lg={7} className="order-xxl-1 order-lg-1 order-2">
              <UserRidesTable userId={id} />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default UserView;
