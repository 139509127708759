import { MonthlyReportsData } from '@/interfaces/MonthlyReport';
import api from '../api';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';

export const montlhyReportApi = api.injectEndpoints({
  endpoints: build => ({
    getMonthlyReport: build.query<
      IPaginateResponse<MonthlyReportsData>,
      [string, IFilters]
    >({
      query: ([driverId, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/drivers/${driverId}/monthly-report`,
          params,
        };
      },
      providesTags: ['MonthlyReport'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetMonthlyReportQuery } = montlhyReportApi;
