import { createFormDataRequest } from '@/helpers/utils';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { IUserReferrals } from '@/interfaces/UserReferrals';
import api from '@/services/api';

export const userReferralsApi = api.injectEndpoints({
  endpoints: build => ({
    getUserReferrals: build.query<
      IPaginateResponse<IUserReferrals>,
      [string, IFilters]
    >({
      query: ([id, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/users/${id}/referrals`,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetUserReferralsQuery } = userReferralsApi;
