import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect } from 'react';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useGetWeeklyChallengeQuery } from '@/services/weeklyChallenges';
import { IWeeklyChallenge } from '@/interfaces/IWeeklyChallenges';

const WeeklyChallengeDetailsCard: FC<ICardDetailsProps<IWeeklyChallenge>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetWeeklyChallengeQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-3">
      <Col xs={12} md={12}>
        <Card className="font-sans-serif">
          <Card.Body>
            <Row className="gx-3">
              <Col xs={12} md={4} className="mb-3 mb-md-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Title
                      </td>
                      <td className="p-1 text-600">{data.title}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        ID
                      </td>
                      <td className="p-1 text-600">{data.id}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xs={12} md={4} className="mb-3 mb-md-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Id:
                      </td>
                      <td className="p-1 text-600">{data.id}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Archived:
                      </td>
                      <td className="p-1 text-600">
                        <Badge bg="info" className="text-white fs--1">
                          {data.archived ? 'YES' : 'NO'}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '20%' }}>
                        Type:
                      </td>
                      <td className="p-1 text-600">
                        <span
                          className={classNames(
                            'badge border link-secondary text-decoration-none'
                          )}
                        >
                          {data.type}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col xs={12} md={4} className="mb-3 mb-sm-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        Start date:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.startDate).toFormat(
                          "dd LLL yyyy 'à' HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        End date:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.endDate).toFormat(
                          "dd LLL yyyy 'à' HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        createdAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.createdAt).toFormat(
                          "dd LLL yyyy 'à' HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '10%' }}>
                        updatedAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.updatedAt).toFormat(
                          "dd LLL yyyy 'à' HH:mm"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default WeeklyChallengeDetailsCard;
