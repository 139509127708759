import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import LeafletMap from './LeafletMap';
import LeafletMapDrawerOverlay, {
  LeafletMapDrawerParams,
  LeafletMapDrawerProps,
} from './LeafletMapDrawerOverlay';

const LeafletMapDrawer: ForwardRefRenderFunction<
  LeafletMapDrawerParams,
  LeafletMapDrawerProps
> = (props, ref) => {
  return (
    <LeafletMap style={{ height: '400px', width: '100%' }}>
      <LeafletMapDrawerOverlay ref={ref} {...props} />
    </LeafletMap>
  );
};

export default forwardRef(LeafletMapDrawer);
