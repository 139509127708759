import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllDriversReferralsHeader from '@/components/table-headers/drivers/referrals/AllDriversReferralHeader';
import AllUsersReferralsHeader from '@/components/table-headers/users/referrals/AllUserReferralsHeader';
import { IUserReferrals, IUserReferralsFilters } from '@/interfaces/UserReferrals';
import { useGetUserReferralsQuery } from '@/services/users/referrals';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type UserReferralsTableProps = {
  id: string;
};

const UserReferralsTable: FC<UserReferralsTableProps> = ({ id }) => {
  const [state, setState] = useState<
    Partial<TableState> & IUserReferralsFilters
  >({
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error } = useGetUserReferralsQuery([
    id,
    {
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    },
  ]);

  const columns = useRef<ColumnDef<IUserReferrals>[]>([
    {
      header: 'Date - Time',
      footer: props => props.column.id,
      accessorFn: ({ createdAt }) => (
        <>
          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy - HH:mm')}
        </>
      ),
      id: 'createdAt',
      cell: info => info.getValue(),
    },    
    {
      header: 'User',
      footer: props => props.column.id,
      accessorFn: ({ referredUser }) => (
        <Link to={`/users/${referredUser?.id}`}>{referredUser?.fullname}</Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: '# Completed Rides',
      footer: props => props.column.id,
      accessorFn: ({ referredUser }) => referredUser?.completedRidesCount,
      id: 'id',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  const filteredData = data?.data.filter(
    referral => referral.referredUser !== null
  );

  return (
      <Table
        HeaderComponent={AllUsersReferralsHeader}
        columns={columns}
        state={state}
        onStateChange={setState}
        data={filteredData}
        meta={data?.meta}
      />
  );
};

export default UserReferralsTable;
