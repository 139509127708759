import Loader from '@/components/common/Loader';
import Select from '@/components/common/Select';
import {
  IWeeklyChallengeFormCreate,
  WeeklyChallengeTYPE,
} from '@/interfaces/IWeeklyChallenges';
import DatePicker from '@/components/common/DatePicker';
import {
  useGetWeeklyChallengeQuery,
  useUpdateWeeklyChallengeMutation,
} from '@/services/weeklyChallenges';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';
import { DateTime } from 'luxon';

interface UpdateWeeklyChallengeCardProps {
  id: string;
}

const UpdateWeeklyChallengeCard: FC<UpdateWeeklyChallengeCardProps> = ({
  id,
}) => {
  const { data, isLoading } = useGetWeeklyChallengeQuery(id);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<IWeeklyChallengeFormCreate>>();

  const [updateWeeklyChallengeMutation] = useUpdateWeeklyChallengeMutation();
  const navigation = useNavigate();

  const onSubmit: SubmitHandler<Partial<IWeeklyChallengeFormCreate>> = ({
    ...newData
  }) => {
    const result = getDifferenceBetweenTwoObjects<IWeeklyChallengeFormCreate>(
      {
        ...data,
        priority: newData.priority
          ? parseInt(newData.priority as unknown as string)
          : undefined,
      },
      newData
    );

    toast.promise(
      updateWeeklyChallengeMutation([
        id,
        {
          ...result,
          type: result.type?.value,
        },
      ]).unwrap(),
      {
        pending: `Modification du challenge en cours...`,
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                {`Le challenge à bien été modifié 🤩`}
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<
    Partial<IWeeklyChallengeFormCreate>
  > = error => console.log(error);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        archived: data.archived,
        endDate: DateTime.fromISO(data?.endDate).toFormat('dd/MMM/yyyy, HH:mm'),
        startDate: DateTime.fromISO(data?.startDate).toFormat(
          'dd/MMM/yyyy, HH:mm'
        ),
        type: WeeklyChallengeTYPE.find(sts => sts.value === data.type),
      });
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <Card.Body className="bg-light">
          <h4 className="mb-0">Modifier le titre {data?.title}</h4>
          <h6 className="mt-1 text-secondary">
            The required fields are marked with{' '}
            <span className="text-danger">*</span>.
          </h6>
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Type :</Form.Label>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <Select
                      options={WeeklyChallengeTYPE}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.type,
                  })}
                >
                  {errors.type?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Amount <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 2"
                  type="number"
                  {...register('number', {
                    required: 'Ce champ est requis.',
                    min: {
                      value: 0,
                      message: 'Ce champ doit contenir au minimum 0.',
                    },
                  })}
                  isInvalid={!!errors.number}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.number?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Priority <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : 1"
                  type="number"
                  {...register('priority', {
                    required: 'Ce champ est requis.',
                    min: {
                      value: 0,
                      message: 'Ce champ doit contenir au minimum 0.',
                    },
                  })}
                  isInvalid={!!errors.priority}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.priority?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label>
                  Start Date <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field }) => (
                    <DatePicker
                      value={field.value as string}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={field.onChange}
                      required
                    />
                  )}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.startDate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3 d-flex flex-column">
                <Form.Label>
                  End Date <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <DatePicker
                      value={field.value}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={field.onChange}
                      required
                    />
                  )}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.endDate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Archived <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Check
                  type="checkbox"
                  {...register('archived')}
                  isInvalid={!!errors.archived}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.archived?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Title <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : La paix est véridique."
                  type="text"
                  {...register('title', {
                    required: 'Ce champ est requis.',
                    minLength: {
                      value: 2,
                      message: 'Ce champ doit contenir au moins 2 caractères.',
                    },
                    maxLength: {
                      value: 255,
                      message:
                        'Ce champ doit contenir au maximum 255 caractères.',
                    },
                  })}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateWeeklyChallengeCard;
