import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import StarRating from '@/components/common/StarRating';
import UnknownBadge from '@/components/common/UnknownBadge';
import { ICardDetailsProps } from '@/interfaces/Card';
import IRide from '@/interfaces/Ride';
import { useGetRideQuery } from '@/services/rides';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect } from 'react';
import { Card, Col, Nav, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserRatingCard: FC<ICardDetailsProps<IRide>> = ({ id, callBack }) => {
  const { data, isLoading, isError, error } = useGetRideQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-2">
      <Col xs={12}>
        <Card className="mb-3 mt-3">
          <Card.Body>
            <Row>
              <h5 className="mb-3 fs-0" style={{ textAlign: 'center' }}>
                User Rating
              </h5>
            </Row>
            <Row className="mt-3">
              <Col md={6} lg={3} className="mb-4 mb-lg-0 text-center">
                {data.author.avatar ? (
                  <ImageOverview images={[data.author.avatar.link]}>
                    {setImgIndex => (
                      <Avatar
                        size="xl"
                        src={data.author.avatar?.link}
                        name={data.author.avatar?.name}
                        onClick={() => setImgIndex(0)}
                      />
                    )}
                  </ImageOverview>
                ) : (
                  <UnknownBadge />
                )}
              </Col>
              <Col lg={2} className="text-left">
                {data.offer.price}€
              </Col>
              <Col lg={2} className="text-left">
                <SoftBadge bg={'success'} className={'text-xl-center'}>
                  {data.status}
                  <FontAwesomeIcon icon={faCheck} className="ms-1" />
                </SoftBadge>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                <Link to={`/users/${data.author.id}`}>
                  {data.author.fullname}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={3}
                className="mb-4 mb-lg-0 mt-2 text-center fw-bold"
              >
                {data.author.referralCode}
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                Rating:
              </Col>
              <Col lg={3} className="text-left mt-2">
                {data.userRideRating &&
                data.userRideRating.rating !== null &&
                data.userRideRating.rating !== undefined ? (
                  <StarRating initialValue={data.userRideRating.rating} />
                ) : (
                  'No rating yet'
                )}
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                Feedback:{' '}
              </Col>
              <Col lg={3} className="text-left mt-3">
                {data.userRideRating?.feedback
                  ? data.userRideRating.feedback
                  : 'No Feedback'}
              </Col>
              <Col lg={3} className="text-center mt-3">
                # Rides Completed:
              </Col>
              <Col lg={3} className="text-left mt-3">
                {data.author.completedRidesCount}
              </Col>
            </Row>
            <Row>
              <Col lg={3} className="text-center mt-3">
                Cancellation:
              </Col>
              <Col lg={3} className="text-left mt-3">
              {data.cancellationReasonMessage || 'No reason'}
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={3} className="mb-4 mb-lg-0 mt-3 text-center">
                Referred:{' '}
              </Col>
              <Col lg={3} className="text-left mt-3">
                {data.userRideRating?.feedback ? 'Yes' : 'No'}
              </Col>
              <Col lg={3} className="text-center mt-3">
                Total Spend:
              </Col>
              <Col lg={3} className="text-left mt-3">
                130€
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default UserRatingCard;
