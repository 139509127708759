import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import UnknownBadge from '@/components/common/UnknownBadge';
import AllDriversHeader from '@/components/table-headers/drivers/AllDriversHeader';
import { GOOGLEMAPS_COORD_LINK } from '@/constants/places';
import { getColorFromService, getNameFromService } from '@/helpers/DriverHelpers';
import { CompanyDrivers } from '@/interfaces/Driver';
import { useGetCompanyDriversQuery } from '@/services/companies';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const CompanyDriversTable: FC<{ companyId: string }> = ({ companyId }) => {
  const [driverData, setDriverData] = useState<CompanyDrivers[]>([]);
  const [state, setState] = useState<Partial<TableState>>({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetCompanyDriversQuery({
    companyId,
    ...state.pagination,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });
  
  useEffect(() => {
    if (data) {
      setDriverData(data.data || []);
    }
  }, [data]);

  const columns = useRef<ColumnDef<CompanyDrivers>[]>([
    {
      header: 'First Name\nLast Name',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ name, id, avatar }) => (
        <Link to={`/drivers/${id}`}>
          <Flex alignItems="center">
            {avatar && (
              <Avatar
                src={avatar.link}
                alt={`${name} Avatar`}
                className="me-2"
                size="2xl"
              />
            )}
            <p className="mb-0">
              <span>{name}</span>
            </p>
          </Flex>
        </Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      enableSorting: false,
      header: 'Email\nPhone Number',
      footer: props => props.column.id,
      accessorFn: ({ email, phone }) => (
        <>
        <Link to={`mailto:${email}`}>{email}</Link>
        <p className="mb-0 text-500">
            <a
              href={`https://wa.me/${phone.replace(/[^0-9]/g, '')}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#007bff' }}
              className="text-decoration-none"
            >
              {phone}
            </a>
          </p>
        </>
      ),
      id: 'email',
      cell: info => info.getValue(),
    },
    {
      header: 'Completed Rides',
      footer: props => props.column.id,
      accessorFn: ({ completedRides }) => completedRides,
      id: 'completedRides',
      cell: info => info.getValue(),
    },
    {
      header: 'Session Status',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ services }) => {
        if (services && services.length > 0) {
          const serviceStatus = services[0].status;
          return (
            <div>
              <SoftBadge
                bg={getColorFromService(serviceStatus)}
                className={'my-2 text-xl-center'}
              >
                {getNameFromService(serviceStatus)}
              </SoftBadge>
            </div>
          );
        }
        return (
          <div>
            <SoftBadge bg="danger" className={'my-2 text-xl-center'}>
              None
            </SoftBadge>
          </div>
        );
      },
      id: 'services.status',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Session',
      footer: props => props.column.id,
      accessorFn: ({ services }) => {
        if (services && services.length > 0) {
          return (
            <>
              {DateTime.fromISO(services[0].createdAt).toFormat('dd/MM/yyyy')}
              <p className="mb-0 text-500">
                {DateTime.fromISO(services[0].createdAt).toFormat('HH:mm')}
              </p>
            </>
          );
        }
        return 'N/A';
      },
      id: 'services.createdAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Last Location',
      footer: props => props.column.id,
      accessorFn: ({ longitude, latitude }) => {
        if (!longitude || !latitude) return 'N/A';
        return (
          <Link to={GOOGLEMAPS_COORD_LINK(latitude, longitude)}>
            localisation google
          </Link>
        );
      },
      id: 'services.lastLocation',
      cell: info => info.getValue(),
    },
    {
      header: 'Car Brand\nCar Plate',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ defaultVehicle }) =>
        defaultVehicle ? (
          <>
            {`${defaultVehicle.vehicle.brand} - ${defaultVehicle.vehicle.model}`}
            <p className="mb-0 text-500">{defaultVehicle.licensePlate}</p>
          </>
        ) : (
          <UnknownBadge />
        ),
      id: 'defaultVehicle',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Table
      HeaderComponent={AllDriversHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      data={driverData}
      meta={data?.meta}
    />
  );
};

export default CompanyDriversTable;
