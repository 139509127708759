import api from './api';
import { objectToQueryString } from '@/helpers/utils';
import { ISupplyHours } from '@/interfaces/ISupplyHours';

export const supplyHoursApi = api.injectEndpoints({
  endpoints: build => ({
    getSupplyHours: build.query<
      ISupplyHours,
      { limit: number; offset: number }
    >({
      query: liveActivityQuery => {
        const params = objectToQueryString(liveActivityQuery);
        return {
          url: `/supplyhours?${params}`,
        };
      },
    }),
  }),
});

export const { useGetSupplyHoursQuery, useLazyGetSupplyHoursQuery } =
  supplyHoursApi;
